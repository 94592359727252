import axios from "axios";

const live = "https://myexcel.app:3000/machineTypes/"
//const url = "http://147.182.172.150:3000/machineTypes/";

class ListAllMachinesType {
  static List(token) {
    return axios.get(live, { headers: { Authorization: `Bearer ${token}` } });
  }
}

export default ListAllMachinesType;
