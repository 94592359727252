import axios from "axios";

const live = "https://myexcel.app:3000/sparePartsLog/export?"
//const url = "http://147.182.172.150:3000/sparePartsLog/export?";

class ExportLogs {
  static Export(sparePartId, startDate, endDate, token) {
    let sparePartsURL = "&spareParts=";
    let output = "";
    for (let i = 0; i < sparePartId.length; i++) {
      output = output + sparePartsURL + sparePartId[i];
    }
    return axios.get(
      `${live}page=1${output}&dateFrom=${startDate} 00:00:01&dateTo=${endDate} 23:59:59`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    );
  }
}

export default ExportLogs;
