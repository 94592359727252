import axios from "axios";

const live = "https://myexcel.app:3000/machines/create"
//const url = "http://147.182.172.150:3000/machines/create";
class CreateNewMachine {
  static Create(token, name, description, machineTypeId, companiesId) {
    return axios.post(
      live,
      {
        name,
        description,
        machineTypeId,
        companiesId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default CreateNewMachine;
