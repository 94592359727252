<template>
  <div class="Export_Schema">
    <Upload_Scheme
      v-if="uploadFile"
      @close="() => (this.uploadFile = false)"
      @load="this.load_export_schemes(1)"
    />
    <div class="Header">
      <a>Export Schema</a>
      <div class="Search_Box">
        <input type="text" v-model="search" placeholder="Search..." />
        <img src="../../assets/SearchIcon.png" />
      </div>
      <button class="Upload_Button" @click="() => (this.uploadFile = true)">
        Upload
      </button>
    </div>
    <div class="Table_Header">
      <p>Part Number</p>
      <p>Action</p>
    </div>

    <div class="Content">
      <div class="Table_Loading" v-if="loading"></div>
      <div class="Table_Loading" v-if="loading"></div>
      <div class="Table_Loading" v-if="loading"></div>

      <p class="Nothing_To_Show" v-if="!loading && data.length == 0">
        Nothing to show
      </p>

      <div
        class="Table_Content"
        v-if="!loading && data.length > 0"
        v-for="item in data"
      >
        <div>
          <p>{{ item }}</p>
          <button @click="this.download_scheme(item)">Download</button>
        </div>
      </div>
    </div>

    <div class="Bottom-Navigator">
      <p
        v-show="this.currentPage != 1 && this.currentPage > 0"
        @click="
          this.manuallyChangePage(
            parseInt(this.currentPage) - 1,
            this.totalPages
          )
        "
      >
        &#8592;
      </p>
      <p>Pages</p>
      <input
        type="number"
        v-model="currentPage"
        v-on:keyup.enter="manuallyChangePage(this.currentPage, this.totalPages)"
      />
      <p>of {{ this.totalPages }}</p>
      <p
        v-show="this.currentPage < this.totalPages"
        @click="
          this.manuallyChangePage(
            parseInt(this.currentPage) + 1,
            this.totalPages
          )
        "
      >
        &#8594;
      </p>
    </div>
  </div>
</template>

<script>
import List_All_Export_Schemes from "../../api/Technology/list_all_export_schemes";
import Download_Scheme from "../../api/Technology/download_scheme";
import List_Export_Schemes_According_To_Search from "../../api/Technology/list_export_schemes_according_to_search";

import Upload_Scheme from "./UploadSchema.vue";

export default {
  name: "Export_Schema",
  components: {
    Upload_Scheme,
  },
  data() {
    return {
      search: "",
      loading: false,
      data: [],
      currentPage: 1,
      totalPages: 1,
      token: "",
      uploadFile: false,
    };
  },

  created() {
    this.token = localStorage.getItem("Token");
    this.load_export_schemes(this.currentPage);
  },

  watch: {
    search() {
      this.load_export_schemes(this.currentPage);
    },
  },

  methods: {
    async load_export_schemes(page) {
      this.uploadFile = false;
      if (this.search.trim().length == 0) {
        await List_All_Export_Schemes.List_All_Export_Schemes(page, this.token)
          .then((res) => {
            this.data = res.data.data;
            if (res.data.pagination.currentPage == 0) this.currentPage = 1;
            else this.currentPage = res.data.pagination.currentPage;
            this.totalPages = res.data.pagination.totalPages;
          })
          .catch((err) => {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          });
      } else {
        await List_Export_Schemes_According_To_Search.List_Export_Schemes_According_To_Search(
          this.search,
          page,
          this.token
        )
          .then((res) => {
            this.data = res.data.data;
            if (res.data.pagination.currentPage == 0) this.currentPage = 1;
            else this.currentPage = res.data.pagination.currentPage;
            this.totalPages = res.data.pagination.totalPages;
          })
          .catch((err) => {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          });
      }
    },

    async download_scheme(part_number) {
      await Download_Scheme.Download_Scheme(part_number, this.token)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${part_number}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    manuallyChangePage(page, totalPages) {
      let tempPage = page;
      if (tempPage < 1) {
        tempPage = 1;
      } else if (tempPage > totalPages) {
        tempPage = totalPages;
      }
      this.load_export_schemes(tempPage);
    },
  },
};
</script>

<style scoped>
.Export_Schema {
  width: 100%;
  margin-top: 55px;
  height: calc(100vh - 55px);
  display: flex;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Header {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
}

.Header > a {
  font-family: Roboto;
  font-size: 15px;
  color: #0097b8;
  font-weight: bold;
  margin-bottom: -20px;
}

.Search_Box {
  width: 250px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.Search_Box > input {
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  color: #a7a7a7;
  width: 200px;
}

.Search_Box > img {
  height: 15px;
}

.Upload_Button {
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 2px;
  color: white;
  background-color: #0097b8;
  padding: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Upload_Button:hover {
  background-color: #02a6ca;
}

.Upload_Button:active {
  background-color: #0286a3;
}

.Table_Header {
  background-color: #0097b8;
  width: calc(100% - 100px);
  margin-top: 20px;
  align-self: center;
  border-radius: 4px;
  height: 30px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Table_Header > p {
  color: #e7ebf1;
  font-weight: 500;
  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 10px;
  margin-top: 10px;
}

.Table_Loading {
  background-color: #f0f2f5;
  width: calc(100% - 100px);
  margin-top: 20px;
  height: 28px;
  align-self: center;
  border-radius: 4px;
  display: flex;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  align-items: center;
  align-self: center;
  animation: gradient 1.3s ease-in-out infinite;
  background: linear-gradient(-60deg, #e9e9e9, #f5f5f5, #d4d4d4, #d6d6d6);
  background-size: 400% 400%;
}

.Nothing_To_Show {
  font-size: 24px;
  align-self: center;
  font-family: Roboto;
  color: #d3cfcf;
}

.Table_Content {
  background-color: #f0f2f5;
  width: calc(100% - 100px);
  margin-top: 15px;
  align-self: center;
  border-radius: 4px;
  height: min-content;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.Table_Content > div {
  align-self: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.Table_Content > div > p {
  margin-left: 30px;
  color: rgb(71, 71, 71);
  font-weight: 400;
  font-family: Roboto;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: 7px;
  word-wrap: break-word;
  margin-right: 22px;
}

.Table_Content > div > button {
  background-color: #dae2e6;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 12px;
  cursor: pointer;
  align-self: flex-end;
  color: #494949;
  margin-right: 13px;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  align-self: center;
}

.Table_Content > div > button:hover {
  background-color: #02a6ca;
  color: white;
}

.Table_Content > div > button:active {
  background-color: #0286a3;
  color: white;
}

.Bottom-Navigator {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.Bottom-Navigator > p {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  margin-left: 4px;
  margin-right: 4px;
}

.Bottom-Navigator > input {
  width: 15px;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
  background-color: transparent;
}

.Bottom-Navigator > p:nth-child(1) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(5) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(1):hover {
  font-size: 14px;
}

.Bottom-Navigator > p:nth-child(5):hover {
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .Search_Box {
    width: 180px;
  }

  .Search_Box > input {
    width: 130px;
  }
}
</style>
