import axios from "axios";

const url = "https://myexcel.app:3000/technology/getAllProductNumbers"

class List_All_Export_Schemes {
  static List_All_Export_Schemes(page, token) {
    return axios.get(`${url}?page=${page}`, { headers: { Authorization: `Bearer ${token}` } });
  }
}

export default List_All_Export_Schemes;
