import axios from "axios";

const live = "https://myexcel.app:3000/spareParts?page="
//const url = "http://147.182.172.150:3000/spareParts?page=";

class ListAllSpareParts {
  static List(token, page, companyId, searchName) {
    return axios.get(`${live}${page}&companiesId=${companyId}&partNumber=${searchName}`, { headers: { Authorization: `Bearer ${token}` } });
  }
}

export default ListAllSpareParts;
