import axios from "axios";

const live = "https://myexcel.app:3000/sparePartsLog?"
//const url = "http://147.182.172.150:3000/sparePartsLog?";

class GetLogs {
  static Get(page, sparePartId, startDate, endDate, token) {
    let sparePartsURL = '&spareParts=';
    let output = ''
    for(let i=0; i < sparePartId.length; i++ ){
      output = output + sparePartsURL + sparePartId[i]
    }
    return axios.get(`${live}page=${page}${output}&dateFrom=${startDate} 00:00:01&dateTo=${endDate} 23:59:59`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default GetLogs;


