import axios from "axios";

const live = "https://myexcel.app:3000/spareParts/addOnStock"
//const url = "http://147.182.172.150:3000/spareParts/addOnStock";

class AddOnStock {
  static Add(sparePartId, onStock, type, token) {
    return axios.post(
      `${live}`,
      {
        sparePartId,
        onStock,
        type
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default AddOnStock;
