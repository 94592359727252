import axios from "axios";

const live = "https://myexcel.app:3000/spareParts/"
//const url = "http://147.182.172.150:3000/spareParts/";

class UpdateSparePart {
  static Update(id, partNumber, minimumBalance, price, machines, description, companiesId, token) {
    return axios.put(
      `${live}${id}`,
      {
        partNumber,
        minimumBalance,
        price,
        machines,
        description,
        companiesId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default UpdateSparePart;



