<template>
  <div class="SpareParts">
    <div class="SpareParts-Header">
      <a>Spare Parts Overview</a>
      <div class="Search-Box">
        <input
          type="text"
          v-model="searchName"
          placeholder="Spare Part Search..."
        />
        <img src="../../assets/SearchIcon.png" />
      </div>
    </div>
    <div class="Second-Header"></div>
    <div class="Table-Header">
      <div>
        <p>Part Number</p>
        <p>Description</p>
        <p>On Stock</p>
        <p>On Order</p>
        <p>Minimum Balance</p>
        <p>Location</p>
        <p>Price €</p>
        <p>Critical Part</p>
        <p></p>
      </div>
    </div>

    <div class="Content">
      <div class="TableContentLoading" v-if="loadingContent"></div>
      <div class="TableContentLoading" v-if="loadingContent"></div>
      <div class="TableContentLoading" v-if="loadingContent"></div>

      <p
        class="Nothing-To-Show"
        v-if="!loadingContent && sparePartsList.length == 0"
      >
        Nothing to show
      </p>
      <div
        class="Table-Content"
        v-if="!loadingContent && sparePartsList.length > 0"
        v-for="item in sparePartsList"
      >
        <div>
          <p
            v-if="openDetailsId != item.id"
            v-bind:class="{ CriticalPart: item.criticalPart }"
          >
            {{ item.partNumber }}
          </p>
          <p v-if="openDetailsId == item.id">{{ item.partNumber }}</p>

          <p
            v-if="openDetailsId != item.id"
            v-bind:class="{ CriticalPart: item.criticalPart }"
          >
            {{ item.description }}
          </p>
          <p v-if="openDetailsId == item.id">{{ item.description }}</p>

          <p
            v-if="openDetailsId != item.id"
            v-bind:class="{ CriticalPart: item.criticalPart }"
          >
            {{ String(item.onStock) }}
          </p>
          <p v-if="openDetailsId == item.id">
            {{ String(item.onStock) }}
          </p>

          <p
            v-if="openDetailsId != item.id"
            v-bind:class="{ CriticalPart: item.criticalPart }"
          >
            {{ String(item.onOrder) }}
          </p>
          <p v-if="openDetailsId == item.id">
            {{ String(item.onOrder) }}
          </p>

          <p
            v-if="openDetailsId != item.id"
            v-bind:class="{ CriticalPart: item.criticalPart }"
          >
            {{ item.minimumBalance }}
          </p>
          <input
            v-if="openDetailsId == item.id"
            type="number"
            v-model="editMBValue"
            class="MBInput"
          />
          <p
            v-if="openDetailsId != item.id"
            v-bind:class="{ CriticalPart: item.criticalPart }"
          >
            {{ String(item.location) }}
          </p>
          <input
            v-if="openDetailsId == item.id"
            type="text"
            v-model="editLocation"
            class="Location_Input"
          />

          <p
            v-if="openDetailsId != item.id"
            v-bind:class="{ CriticalPart: item.criticalPart }"
          >
            {{ item.price }}
          </p>
          <p v-if="openDetailsId == item.id">
            {{ item.price }}
          </p>

          <p
            v-if="openDetailsId != item.id"
            v-bind:class="{ CriticalPart: item.criticalPart }"
          >
            {{ String(item.criticalPart) }}
          </p>
          <input
            type="checkbox"
            class="Critical_Part_Input"
            v-if="openDetailsId == item.id"
            v-model="editCPValue"
          />
          <img
            src="../../assets/ViewMore.png"
            @click="
              open_Details(
                item.id,
                item.minimumBalance,
                item.location,
                item.criticalPart
              )
            "
          />
        </div>
        <div class="PartDetails" v-if="openDetailsId == item.id">
          <table class="Spare-Part-Table">
            <tr style="text-align: center; background-color: #0097b8">
              <th class="th">Related Machines / Crimp tools</th>
              <th class="th">Description</th>
              <th class="th">Type</th>
            </tr>
            <tr v-if="this.loadingTable" style="text-align: center">
              <td colspan="7" class="td" style="border-bottom: none">
                Loading...
              </td>
            </tr>
            <tr
              v-if="!this.loadingTable"
              style="text-align: center"
              v-for="part in sparePartDetails"
            >
              <td class="td">{{ part.machine.name }}</td>
              <td class="td">{{ part.machine.description }}</td>
              <td class="td">{{ part.machine.machineType.name }}</td>
            </tr>
          </table>
          <button @click="open_Stock_Box">Stock</button>
          <button @click="open_Order_Box">Orders</button>
          <button
            @click="
              discard_Changes(
                item.id,
                item.minimumBalance,
                item.location,
                item.criticalPart
              )
            "
          >
            Discard
          </button>
          <button
            v-if="
              editMBValue != item.minimumBalance ||
              editLocation != item.location ||
              editCPValue != item.criticalPart
            "
            @click="
              save(
                item.id,
                item.partNumber,
                editMBValue,
                item.price,
                editLocation,
                editCPValue
              )
            "
          >
            Save
          </button>
          <button
            class="Disabled-Button"
            v-if="
              editMBValue == item.minimumBalance &&
              editLocation == item.location &&
              editCPValue == item.criticalPart
            "
          >
            Save
          </button>
        </div>
      </div>

      <div class="Bottom-Navigator">
        <p
          v-show="this.currentPage != 1 && this.currentPage > 0"
          @click="
            this.list_All_Spare_Parts(
              this.token,
              parseInt(this.currentPage) - 1,
              this.searchName
            )
          "
        >
          &#8592;
        </p>
        <p>Pages</p>
        <input
          type="number"
          v-model="currentPage"
          v-on:keyup.enter="
            manually_Change_Page(this.token, this.currentPage, this.totalPages)
          "
        />
        <p>of {{ this.totalPages }}</p>
        <p
          v-show="this.currentPage < this.totalPages"
          @click="
            this.list_All_Spare_Parts(
              this.token,
              parseInt(this.currentPage) + 1,
              this.searchName
            )
          "
        >
          &#8594;
        </p>
      </div>
    </div>
    <div
      class="Order-Screen"
      v-if="orderScreen"
      v-click-outside="close_Order_box"
    >
      <p>Orders Management</p>
      <input
        type="number"
        placeholder="Qty"
        v-model="orderQuantity"
        v-on:keyup.enter="order(this.openDetailsId, this.orderQuantity)"
      />

      <select name="Type" v-model="this.orderType">
        <option value="0" disabled selected>Transaction Type</option>
        <option value="1">PO</option>
        <option value="2">PO-</option>
      </select>

      <div class="Buttons">
        <button @click="close_Order_box">Decline</button>
        <button
          v-if="!this.loadingButton"
          @click="order(this.openDetailsId, this.orderQuantity, this.orderType)"
        >
          Confirm
        </button>

        <img
          v-if="this.loadingButton"
          class="LoadingImage"
          src="../../assets/LoadingButton.gif"
        />
      </div>
    </div>

    <div
      class="Stock-Screen"
      v-if="stockScreen"
      v-click-outside="close_Stock_Box"
    >
      <p>Stock Management</p>
      <input
        type="number"
        placeholder="Qty"
        v-model="addStockQty"
        v-on:keyup.enter="order(this.openDetailsId, this.orderQuantity)"
      />
      <select name="Type" v-model="this.stockType">
        <option value="0" disabled selected>Transaction Type</option>
        <option value="1">X+</option>
        <option value="2">PO</option>
        <option value="3">X-</option>
      </select>
      <div class="Buttons">
        <button @click="close_Stock_Box">Decline</button>

        <button
          v-if="!this.loadingButton"
          @click="
            this.stock_Manage(
              this.openDetailsId,
              this.addStockQty,
              this.stockType
            )
          "
        >
          Confirm
        </button>

        <img
          v-if="this.loadingButton"
          class="LoadingImage"
          src="../../assets/LoadingButton.gif"
        />
      </div>
    </div>
  </div>
</template>

<script>
import listAllSpareParts from "../../api/ListAllSpareParts";
import listSparePartDetails from "../../api/ListSparePartDetails";
import updateSparePart from "../../api/EditSparePartOverview";
import { notify } from "@kyvg/vue3-notification";
import OrderSparePart from "../../api/OrderSparePart";
import vClickOutside from "click-outside-vue3";
import AddOnStock from "../../api/addOnStock";

export default {
  name: "SpareParts",
  data() {
    return {
      loadingContent: false, // Loading full content
      currentPage: 0, // Broj trenutne stranice
      totalPages: 0, // Broj total stranica
      sparePartsList: [], // Lista Spare Parts
      sparePartDetails: [], // Detalji o odredjenom Spare Partu
      token: "", // Token
      companyId: 0, // ID Kompanije
      openDetailsId: "", // ID Spare Parta za detaljni prikaz
      editMBValue: "", // Nova vrijednost Minimum Balansa za edit
      editLocation: "", // Nova vrijednost Lokacije za edit
      editCPValue: false, // Nova vrijednost Critical Part za edit
      orderScreen: false, // Prikaz ORDER Screena
      orderQuantity: 0, // Kolicina ORder inputa
      orderType: 0, // Order Type
      stockScreen: false, // Prikaz Stock Screana
      addStockQty: 0, // Kolicina Stock inputa
      stockType: 0, // Stock type
      loadingTable: false, // Loading tabele
      loadingButton: false, // Loading CONFIRM dugmica
      searchName: "", // Search input
    };
  },
  async created() {
    this.token = localStorage.getItem("Token");
    let temp = localStorage.getItem("Company");
    temp = JSON.parse(temp);
    this.companyId = temp.id;
    this.list_All_Spare_Parts(this.token, 1, this.searchName);
  },

  watch: {
    // whenever question changes, this function will run
    searchName(searchName, oldSearchName) {
      this.list_All_Spare_Parts(this.token, 1, searchName);
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {
    async list_All_Spare_Parts(token, page, searchName) {
      this.loadingContent = true;
      await listAllSpareParts
        .List(token, page, this.companyId, searchName)
        .then((res) => {
          this.loadingContent = false;
          this.currentPage = res.data.pagination.currentPage;
          this.totalPages = res.data.pagination.totalPages;
          this.sparePartsList = res.data.data;
          let container = document.querySelector(".Content");
          container.scrollTop = 0;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    manually_Change_Page(token, page, totalPages) {
      let tempPage = page;
      if (tempPage < 1) {
        tempPage = 1;
      } else if (tempPage > totalPages) {
        tempPage = totalPages;
      }
      this.list_All_Spare_Parts(token, tempPage, this.searchName);
    },

    open_Details(id, mb, location, criticalPart) {
      if (id == this.openDetailsId) {
        this.openDetailsId = "";
        this.editMBValue = "";
        this.editLocation = "";
        this.editCPValue = false;
      } else {
        this.openDetailsId = id;
        this.editMBValue = mb;
        this.editCPValue = criticalPart;
        if (location == null) {
          this.editLocation = "null";
        } else {
          this.editLocation = location;
        }

        this.list_Spare_Part_Details(this.token, this.openDetailsId);
      }
    },

    async list_Spare_Part_Details(token, id) {
      this.loadingTable = true;
      listSparePartDetails
        .List(token, id)
        .then((res) => {
          this.sparePartDetails = res.data.data.machineSparePartRelated;
          this.loadingTable = false;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    discard_Changes(id, mb, location, criticalPart) {
      this.openDetailsId = id;
      this.editMBValue = mb;
      this.editCPValue = criticalPart;
      if (location == null) {
        this.editLocation = "null";
      } else {
        this.editLocation = location;
      }
    },

    async save(id, partNumber, minimumBalance, price, location, criticalPart) {
      if (minimumBalance.length == 0 || location.length == 0) {
        notify({
          title: "Unkown Minimum Balance or Location !",
          type: "Error",
        });
      } else {
        this.loadingContent = true;
        await updateSparePart
          .Update(
            id,
            partNumber,
            minimumBalance,
            price,
            location,
            criticalPart,
            this.companyId,
            this.token,
          )
          .then((res) => {
            if (res.data.success) {
              this.list_All_Spare_Parts(
                this.token,
                this.currentPage,
                this.searchName,
                
              );
              this.loadingContent = false;
              notify({
                title: "Successfully update !",
              });
            }
          })
          .catch((err) => {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          });
      }
    },

    open_Order_Box() {
      this.orderScreen = true;
      this.orderQuantity = 0;
      this.orderType = 0;
    },

    open_Stock_Box() {
      this.stockScreen = true;
      this.addStockQty = 0;
      this.stockType = 0;
    },

    close_Order_box() {
      this.orderScreen = false;
    },

    close_Stock_Box() {
      this.stockScreen = false;
    },

    async order(id, quantity, type) {
      if (quantity < 1) {
        notify({
          title: "You can't make changes with less than 1 piece !",
          type: "Error",
        });
      } else {
        this.loadingButton = true;
        await OrderSparePart.Order(
          parseInt(id),
          parseInt(quantity),
          parseInt(type),
          this.token
        )
          .then((res) => {
            if (res.data.success) {
              notify({
                title: "Changes successful !",
              });
              this.orderScreen = false;
              this.orderQuantity = 0;
              this.list_All_Spare_Parts(
                this.token,
                this.currentPage,
                this.searchName
              );
              this.loadingButton = false;
            }
          })
          .catch((err) => {
            if (
              err.response.data.message ==
              "The total On order value cannot be less than zero"
            ) {
              notify({
                title: "Input value higher than on 'On Order' !",
                type: "Error",
              });
              this.loadingButton = false;
            } else {
              this.token = localStorage.removeItem("Token");
              this.$router.push("/");
            }
          });
      }
    },

    async stock_Manage(sparePartId, onStock, type) {
      if (onStock < 1) {
        notify({
          title: "You can't add on stock less than 1 piece !",
          type: "Error",
        });
      } else if (type != 1 && type != 2 && type != 3) {
        notify({
          title: "You must choose Transaction Type !",
          type: "Error",
        });
      } else {
        this.loadingButton = true;
        AddOnStock.Add(
          parseInt(sparePartId),
          parseInt(onStock),
          parseInt(type),
          this.token
        )
          .then((res) => {
            if (res.data.success) {
              notify({
                title: "Transaction successful !",
              });
              this.stockScreen = false;
              this.addStockQty = 0;
              this.stockType = 0;
              this.list_All_Spare_Parts(
                this.token,
                this.currentPage,
                this.searchName
              );
              this.loadingButton = false;
            }
          })
          .catch((err) => {
            if (
              err.response.data.message == "Add more to on Stock than ordered"
            ) {
              this.loadingButton = false;
              notify({
                title: "Check order quantity !",
                type: "Error",
              });
            } else {
              this.token = localStorage.removeItem("Token");
              this.$router.push("/");
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.SpareParts {
  width: 100%;
  margin-top: 55px;
  height: calc(100vh - 55px);
  display: flex;
  flex-direction: column;
}

.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-top: 10px;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.Nothing-To-Show {
  font-size: 24px;
  align-self: center;
  font-family: Roboto;
  color: #d3cfcf;
  cursor: pointer;
}
.SpareParts-Header {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
}

.SpareParts-Header > a {
  font-family: Roboto;
  font-size: 15px;
  color: #0097b8;
  font-weight: bold;
  margin-bottom: -20px;
}

.Search-Box {
  width: 250px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.Search-Box > input {
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  color: #a7a7a7;
  width: 200px;
}

.Search-Box > img {
  height: 15px;
}

.Second-Header {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-top: 40px;
}

.Table-Header {
  background-color: #0097b8;
  width: calc(100% - 100px);
  margin-top: 20px;
  align-self: center;
  display: flex;
  border-radius: 4px;
  height: 30px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  align-items: center;
}

.Table-Header > div {
  display: flex;
  align-items: center;
  height: 30px;
}

.Table-Header > div > p {
  color: #e7ebf1;
  font-weight: 500;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  margin-left: 70px;
  width: 113px;
  text-align: center;
}

.Table-Header > div > p:last-child {
  width: 30px;
  margin: auto;
  margin-right: 20px;
}

.TableContentLoading {
  background-color: #f0f2f5;
  width: calc(100% - 100px);
  margin-top: 20px;
  height: 28px;
  align-self: center;
  border-radius: 4px;
  display: flex;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  align-items: center;
  align-self: center;
  animation: gradient 1.3s ease-in-out infinite;
  background: linear-gradient(-60deg, #e9e9e9, #f5f5f5, #d4d4d4, #d6d6d6);
  background-size: 400% 400%;
}

.Table-Content {
  background-color: #f0f2f5;
  width: calc(100% - 100px);
  margin-top: 15px;
  align-self: center;
  border-radius: 4px;
  height: min-content;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.Table-Content > div {
  align-items: center;
  align-self: center;
  display: flex;
}

.Table-Content > .PartDetails {
  height: min-content;
  position: relative;
}

.Table-Content > .PartDetails > button {
  position: absolute;
  margin: auto;
  bottom: 10px;
  right: 20px;
  width: 50px;
  height: 17px;
  background-color: #0097b8;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 11px;
  color: #8c8c94;
  line-height: 5px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  font-weight: 300;
}

.Table-Content > .PartDetails > button:hover {
  background-color: #03abd1;
}

.Table-Content > .PartDetails > button:active {
  background-color: #018baa;
}

.Table-Content > .PartDetails > button:nth-child(4) {
  right: 90px;
  background-color: #d13232;
  color: white;
  box-shadow: none;
}

.Table-Content > .PartDetails > button:nth-child(4):hover {
  background-color: #e73838;
}

.Table-Content > .PartDetails > button:nth-child(4):active {
  background-color: #bb2c2c;
}

.Table-Content > .PartDetails > button:nth-child(2) {
  right: 230px;
  background-color: #dae2e6;
  color:#494949;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.Table-Content > .PartDetails > button:nth-child(2):hover {
  background-color: #ccd0d3;
}

.Table-Content > .PartDetails > button:nth-child(2):active {
  background-color: rgb(181, 188, 192);
}

.Table-Content > .PartDetails > button:nth-child(3) {
  right: 160px;
  background-color: #dae2e6;
  color:#494949;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.Table-Content > .PartDetails > button:nth-child(3):hover {
  background-color: #ccd0d3;
}

.Table-Content > .PartDetails > button:nth-child(3):active {
  background-color: rgb(181, 188, 192);
}

.Table-Content > .PartDetails > .Disabled-Button {
  background-color: #bdbdc4;
  cursor: unset;
  color:#494949;
  box-shadow: none;
}

.Table-Content > .PartDetails > .Disabled-Button:hover {
  background-color: #bdbdc4;
}

.Table-Content > .PartDetails > .Disabled-Button:active {
  background-color: #bdbdc4;
}

.th {
  font-family: Roboto;
  font-size: 13px;
  color: white;
  font-weight: 300;
}

.td {
  font-family: Roboto;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 0.5px dashed #8f8f8f;
}

.Table-Content > div > p {
  margin-left: 70px;
  width: 113px;
  text-align: center;
  font-weight: 400;
  font-family: Roboto;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: 7px;
  word-wrap: break-word;
  color: rgb(71, 71, 71);
}

.CriticalPart {
  color: #ec2020 !important;
}

.Table-Content > div > img {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin: auto;
  margin-right: 20px;
}

.Table-Content > div > img:hover {
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 50%;
}

.Bottom-Navigator {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.Bottom-Navigator > p {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  margin-left: 4px;
  margin-right: 4px;
}

.Bottom-Navigator > input {
  width: 15px;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Bottom-Navigator > p:nth-child(1) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(5) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(1):hover {
  font-size: 14px;
}

.Bottom-Navigator > p:nth-child(5):hover {
  font-size: 14px;
}

.MBInput {
  width: 20px;
  margin-left: 110px;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  text-align: center;
  font-weight: 400;
  font-family: Roboto;
  font-size: 13px;
  background-color: transparent;
}

.Location_Input {
  width: 60px;
  margin-left: 143px;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  text-align: center;
  margin-right: 25px;
  font-weight: 400;
  font-family: Roboto;
  font-size: 13px;
  background-color: transparent;
}

.Critical_Part_Input {
  margin-left: 120px;
  cursor: pointer;
  font-weight: 400;
  font-family: Roboto;
  font-size: 13px;
  background-color: transparent;
}

.Order-Screen {
  height: 170px;
  width: 400px;
  background-color: #dcdcdc;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px 3px rgb(190, 190, 190);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0 99999px rgba(73, 73, 73, 0.5);
  border-radius: 3px;
}

.Order-Screen > select {
  background-color: white;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
  height: 20px;
  background-color: #f9f9f9;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.Order-Screen > p {
  font-family: Roboto;
  font-weight: 600;
  font-size: 15px;
  color: #0097b8;
}

.Order-Screen > input {
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  width: 30px;
  text-align: center;
  background-color: transparent;
}

.Stock-Screen {
  height: 170px;
  width: 400px;
  background-color: #dcdcdc;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px 3px rgb(190, 190, 190);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0 99999px rgba(73, 73, 73, 0.5);
  border-radius: 3px;
}

.Stock-Screen > select {
  background-color: white;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
  height: 20px;
  background-color: #f9f9f9;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.Stock-Screen > p {
  font-family: Roboto;
  font-weight: 600;
  font-size: 15px;
  color: #0097b8;
}

.Stock-Screen > input {
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  width: 30px;
  text-align: center;
  background-color: transparent;
}

.Buttons {
  margin-top: 20px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Buttons > button {
  border: none;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 300;
  color: white;
  border-radius: 3px;
  padding: 2px;
  width: 75px;
  height: 20px;
  cursor: pointer;
}

.Buttons > button:nth-child(1) {
  background-color: #696969;
}

.Buttons > button:nth-child(2) {
  background-color: #0097b8;
}

.Buttons > button:nth-child(1):hover {
  background-color: #505050;
}

.Buttons > button:nth-child(2):hover {
  background-color: #007e9b;
}

.Buttons > button:nth-child(1):active {
  background-color: #696969;
}

.Buttons > button:nth-child(2):active {
  background-color: #0097b8;
}

.Spare-Part-Table {
  width: 600px;
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 40px;
  border-collapse: collapse;
}

.LoadingImage {
  margin-top: -5px;
  height: 25px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 1620px) {
  .Table-Header > div > p {
    margin-left: 50px;
  }

  .Table-Content > div > p {
    margin-left: 50px;
  }

  .MBInput {
    margin-left: 95px;
  }

  .Location_Input {
    margin-left: 120px;
  }

  .Critical_Part_Input {
    margin-left: 100px;
  }
}

@media screen and (max-width: 1450px) {
  .Table-Header > div > p {
    margin-left: 30px;
  }

  .Table-Content > div > p {
    margin-left: 30px;
  }

  .MBInput {
    margin-left: 75px;
  }

  .Location_Input {
    margin-left: 100px;
  }

  .Critical_Part_Input {
    margin-left: 80px;
  }
}

@media screen and (max-width: 1300px) {
  .Table-Header > div > p {
    margin-left: 10px;
  }

  .Table-Content > div > p {
    margin-left: 10px;
  }

  .MBInput {
    margin-left: 60px;
  }

  .Location_Input {
    margin-left: 80px;
    margin-right: 20px;
  }

  .Critical_Part_Input {
    margin-left: 58px;
  }
}

@media screen and (max-width: 1150px) {
  .Table-Header > div > p {
    margin-left: 5px;
  }

  .Table-Content > div > p {
    margin-left: 5px;
  }

  .MBInput {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1100px) {
  .Table-Header > div > p {
    margin-left: 1px;
    width: 110px;
  }

  .Table-Content > div > p {
    margin-left: 1px;
    width: 110px;
  }

  .MBInput {
    margin-left: 45px;
  }

  .Location_Input {
    margin-left: 70px;
  }

  .Critical_Part_Input {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1040px) {
  .Table-Header > div > p {
    width: 100px;
  }

  .Table-Content > div > p {
    width: 100px;
  }

  .MBInput {
    margin-left: 40px;
  }

  .Location_Input {
    margin-left: 60px;
  }

  .Critical_Part_Input {
    margin-left: 40px;
  }
}

@media screen and (max-width: 960px) {
  .Table-Header > div > p {
    width: 90px;
  }

  .Table-Content > div > p {
    width: 90px;
  }

  .MBInput {
    margin-left: 35px;
  }

  .Location_Input {
    margin-left: 50px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 880px) {
  .SpareParts-Header {
    margin-left: 2%;
    margin-right: unset;
  }

  .Second-Header {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 70px;
  }

  .Second-Header > button {
    margin-right: 0px;
  }

  .Table-Header {
    width: 96vw;
  }

  .Table-Content {
    width: 96vw;
  }

  .Search-Box {
    margin-top: 30px;
  }

  .Table-Header > div > p {
    width: 90px;
  }

  .Table-Content > div > p {
    width: 90px;
  }

  .Spare-Part-Table {
    width: 96%;
    margin-left: 2%;
  }
}


@media screen and (max-width: 800px) {
  .Table-Header > div > p {
    margin-left: 1px;
    margin-right: 1px;
    width: 80px;
    font-size: 12px;
  }

  .Table-Content > div > p {
    margin-left: 1px;
    margin-right: 1px;
    width: 80px;
    font-size: 12px;
  }

  .MBInput {
    margin-left: 30px;
    margin-right: -15px;
  }
}

@media screen and (max-width: 730px) {
  .Table-Header > div > p {
    width: 70px;
  }

  .Table-Content > div > p {
    width: 70px;
  }

  .Location_Input {
    margin-left: 25px;
  }
}

@media screen and (max-width: 650px) {
  .Table-Header > div > p {
    width: 60px;
  }

  .Table-Content > div > p {
    width: 60px;
  }

  .MBInput {
    margin-left: 25px;
  }

  .Location_Input {
    margin-left: 20px;
  }

  .Critical_Part_Input {
    margin-left: 20px;
  }
}

@media screen and (max-width: 570px) {
  .Table-Header > div > p {
    font-size: 10px;
    width: 50px;
  }

  .Table-Content > div > p {
    font-size: 10px;
    width: 50px;
  }

  .MBInput {
    margin-left: 17px;
    font-size: 10px;
  }

  .Location_Input {
    margin-left: 15px;
    font-size: 10px;
    margin-right: 0px;
  }

  .th {
    font-size: 10px;
  }

  .td {
    font-size: 10px;
  }

  .tdCritical {
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .Table-Header > div > p {
    font-size: 10px;
    width: 40px;
  }

  .Table-Content > div > p {
    font-size: 10px;
    width: 40px;
  }

  .Critical_Part_Input {
    margin-left: -5px;
  }
}
</style>
