import axios from "axios";

const url = "https://myexcel.app:3000/technology/upload";

class Upload_Scheme {
  static Upload_Scheme(part_number, file, token, config = {}) {
    let formData = new FormData();

    formData.append("productNumber", part_number);
    formData.append("zipFile", file);

    return axios.post(`${url}`, formData, {
      headers: { Authorization: `Bearer ${token}` },
      onUploadProgress: config.onUploadProgress || (() => {}),
      cancelToken: config.cancelToken,
    });
  }
}
export default Upload_Scheme;
