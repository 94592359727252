import axios from "axios";
const url = "https://myexcel.app:3000/technology/getDownload";

class Download_Scheme {
  static Download_Scheme(part_number, token) {
    return axios.get(`${url}?productNumber=${part_number}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });
  }
}

export default Download_Scheme;
