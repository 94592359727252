import axios from "axios";

const live = "https://myexcel.app:3000/spareParts/create"
//const url = "http://147.182.172.150:3000/spareParts/create";

class CreateNewSparePart {
  static Create(partNumber, minimumBalance, price, machines, description, location, onStock, token, companiesId) {
    return axios.post(
      live,
      {
        partNumber,
        minimumBalance,
        price,
        machines,
        description,
        location,
        onStock,
        companiesId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default CreateNewSparePart;
