<template>
  <div class="Management">
    <div class="Management-Header">
      <a>Spare Parts Management</a>
    </div>

    <div class="Content">
      <div class="Full-Screen">
        <!-- FIRST ROW-->
        <div class="First-Row">
          <div class="Box-One">
            <p>Add new Machine / Crimp tool</p>
            <div style="display: flex; justify-content: flex-start">
              <label for="Name">Name</label>
              <label for="Description">Description</label></div>
            
            <div>
              <input
                type="text"
                id="Name"
                v-model="newMachineName"
                v-bind:class="{
                  newMachineNameError: this.newMachineInputError,
                }"
              />
              <input
                type="text"
                id="Name"
                v-model="newMachineDescription"
                v-bind:class="{
                  newMachineDescriptionError: this.newMachineDescriptionError,
                }"
              />
              <select
                name="Type"
                v-model="newMachineType"
                v-bind:class="{
                  newMachineTypeError: this.newMachineTypeError,
                }"
              >
                <option value="" disabled selected>Type</option>
                <option :value="item.id" v-for="item in allMachinesType">
                  {{ item.name }}
                </option>
              </select>
              <button
                v-if="!newMachineLoading"
                @click="
                  createNewMachine(
                    this.token,
                    this.newMachineName,
                    this.newMachineDescription,
                    this.newMachineType
                  )
                "
              >
                Add
              </button>
              <button v-if="newMachineLoading">
                <img src="../../assets/LoadingButton.gif" />
              </button>
            </div>
          </div>

          <div class="Box-Two">
            <p>Add new Spare Parts</p>
            <div>
              <div class="Part-Number">
                <label>Part Number</label>
                <input
                  type="text"
                  v-model="newSparePart"
                  v-bind:class="{
                    newSparePartError: this.newSparePartError,
                  }"
                />
              </div>
              <div class="Description">
                <label>Description</label>
                <input
                  type="text"
                  v-model="newSparePartDescription"
                  v-bind:class="{
                    newSparePartError: this.newSparePartDescriptionError,
                  }"
                />
              </div>
              <div class="Minimum-Balance">
                <label>Minimum Balance</label>
                <input
                  type="number"
                  v-model="newSparePartMinimumBalance"
                  v-bind:class="{
                    newSparePartError: this.newSparePartMinimumBalanceError,
                  }"
                />
              </div>
              <div class="Price">
                <label>Price €</label>
                <input
                  type="number"
                  v-model="newSparePartPrice"
                  v-bind:class="{
                    newSparePartError: this.newSparePartPriceError,
                  }"
                />
              </div>

              <div class="Location">
                <label>Location</label>
                <input
                  type="text"
                  placeholder="Null"
                  v-model="newSparePartLocation"
                />
              </div>

              <div class="Stock">
                <label>Stock</label>
                <input
                  placeholder="0"
                  type="number"
                  v-model="newSparePartStock"
                />
              </div>

              <div
                v-bind:class="{
                  RelatedMachines: !this.newSparePartRelatedMachinesError,
                  RelatedMachinesError: this.newSparePartRelatedMachinesError,
                }"
              >
                <a @click="toggleRelatedMachines">Related Machines</a>
                <img
                  @click="toggleRelatedMachines"
                  src="../../assets/DownArrow.png"
                />

                <div
                  class="Related-Machines-Box"
                  v-if="this.relatedMachines"
                  v-click-outside="hideRelatedMachines"
                >
                  <div class="Related-Machines-Search-Box">
                    <input
                      type="text"
                      v-model="searchRelatedBox"
                      placeholder="Machine search..."
                    />
                    <img src="../../assets/SearchIcon.png" />
                  </div>

                  <p>Select Machines / Crimp Tools</p>
                  <div class="Related-Machines-List">
                    <a
                      v-if="!this.loadingBox2"
                      v-for="item in this.relatedMachinesArray"
                      @click="selectRelatedMachines(item.id)"
                      v-bind:class="{
                        SelectedRelatedMachine:
                          this.newSparePartRelatedMachines.includes(item.id),
                      }"
                    >
                      {{ item.name }}</a
                    >

                    <a v-if="this.loadingBox2" style="align-self: center"
                      >Loading...</a
                    >
                  </div>

                  <div class="RelatedMachines-Navigator">
                    <p
                      v-show="
                        this.relatedMachinesCurrentPage != 1 &&
                        this.relatedMachinesCurrentPage > 0
                      "
                      @click="
                        this.listAllMachinesForRelatedBox(
                          this.token,
                          parseInt(this.relatedMachinesCurrentPage) - 1,
                          this.searchRelatedBox
                        )
                      "
                    >
                      &#8592;
                    </p>
                    <p>Pages</p>
                    <input
                      type="number"
                      v-model="relatedMachinesCurrentPage"
                      v-on:keyup.enter="
                        manuallyChangePageRelatedMachines(
                          token,
                          this.relatedMachinesCurrentPage,
                          this.machinesTotalPages,
                          this.searchRelatedBox
                        )
                      "
                    />
                    <p>of {{ this.relatedMachinesTotalPages }}</p>
                    <p
                      v-show="
                        this.relatedMachinesCurrentPage !=
                        this.relatedMachinesTotalPages
                      "
                      @click="
                        this.listAllMachinesForRelatedBox(
                          this.token,
                          parseInt(this.relatedMachinesCurrentPage) + 1,
                          this.searchRelatedBox
                        )
                      "
                    >
                      &#8594;
                    </p>
                  </div>
                </div>
              </div>
              <button
                v-if="!newSparePartLoading"
                @click="
                  createNewSparePart(
                    this.newSparePart,
                    this.newSparePartMinimumBalance,
                    this.newSparePartPrice,
                    this.newSparePartRelatedMachines,
                    this.newSparePartDescription,
                    this.newSparePartLocation,
                    this.newSparePartStock,
                    this.token
                  )
                "
              >
                Add
              </button>

              <button v-if="newSparePartLoading">
                <img src="../../assets/LoadingButton.gif" />
              </button>
            </div>
          </div>
        </div>

        <!-- SECOND ROW -->
        <div class="Second-Row">
          <div
            v-bind:class="{
              BoxThree: !this.allMachinesLoading,
              LoadingBoxThree: this.allMachinesLoading,
            }"
          >
            <div class="Search-Box" v-if="!this.allMachinesLoading">
              <input
                type="text"
                v-model="searchMachine"
                placeholder="Machine search..."
              />
              <img
                v-bind:class="{
                  LoadingImg: this.searchLoadingMachine,
                }"
                src="../../assets/SearchIcon.png"
              />
            </div>
            <table
              class="Machine-Table"
              style="width: 95%; margin-top: 40px"
              v-if="!this.allMachinesLoading"
            >
              <tr style="text-align: center">
                <th class="th">Name</th>
                <th class="th">Type</th>
                <th class="th">Description</th>
                <th class="th">Action</th>
                <th class="th">Action</th>
              </tr>

              <tr style="text-align: center" v-for="item in this.allMachines">
                <td class="td" v-if="editMachineId == item.id">
                  <input
                    type="text"
                    v-bind:class="{
                      EditMachineName: !this.editMachineError,
                      EditMachineNameError: this.editMachineError,
                    }"
                    v-model="this.editMachineName"
                  />
                </td>
                <td class="td" v-if="editMachineId != item.id">
                  {{ item.name }}
                </td>

                <td class="td" v-if="editMachineId != item.id">
                  {{ item.machineType.name }}
                </td>
                <td class="td" v-if="editMachineId != item.id">
                  {{ item.description }}
                </td>
                <td class="td" v-if="editMachineId == item.id">
                  <select
                    name="Type"
                    v-model="this.editMachineType"
                    class="editableSelectMachine"
                  >
                    <option value="" disabled selected>Type</option>
                    <option value="1">Machine</option>
                    <option value="2">Crimp Tool</option>
                  </select>
                </td>
                <td class="td" v-if="editMachineId == item.id">
                  <input
                    type="text"
                    v-model="this.editMachineDescription"
                    class="EditMachineName"
                    v-bind:class="{
                      EditMachineName: !this.editMachineDescriptionError,
                      EditMachineNameError: this.editMachineDescriptionError,
                    }"
                  />
                </td>

                <td class="td" v-if="editMachineId != item.id">
                  <img
                    @click="
                      choseMachineToEdit(
                        item.id,
                        item.name,
                        item.machineType.id,
                        item.description
                      )
                    "
                    src="../../assets/Edit.png"
                    class="Edit-Icon"
                  />
                </td>
                <td class="td" v-if="editMachineId == item.id">
                  <img
                    @click="denyMachineEdit"
                    src="../../assets/Deny.png"
                    class="Edit-Icon"
                  />
                  <img
                    @click="
                      updateMachine(
                        this.editMachineId,
                        this.editMachineName,
                        this.editMachineType,
                        this.editMachineDescription,
                        this.token
                      )
                    "
                    src="../../assets/Confirm.png"
                    class="Edit-Icon"
                  />
                </td>
                <td class="td">
                  <button
                    class="Delete-Button"
                    @click="this.deleteMachine(item.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </table>
            <div class="Bottom-Navigator" v-if="!this.allMachinesLoading">
              <p
                v-show="this.machinesCurrentPage > 1"
                @click="
                  this.listAllMachines(
                    this.token,
                    parseInt(this.machinesCurrentPage) - 1,
                    this.searchMachine
                  )
                "
              >
                &#8592;
              </p>
              <p>Pages</p>
              <input
                type="number"
                v-model="machinesCurrentPage"
                v-on:keyup.enter="
                  manuallyChangePage(
                    token,
                    this.machinesCurrentPage,
                    this.machinesTotalPages,
                    this.searchMachine
                  )
                "
              />
              <p>of {{ this.machinesTotalPages }}</p>
              <p
                v-show="this.machinesCurrentPage < this.machinesTotalPages"
                @click="
                  this.listAllMachines(
                    this.token,
                    parseInt(this.machinesCurrentPage) + 1,
                    this.searchMachine
                  )
                "
              >
                &#8594;
              </p>
            </div>
          </div>

          <div
            v-bind:class="{
              BoxFour: !this.allSparePartsLoading,
              LoadingBoxFour: this.allSparePartsLoading,
            }"
          >
            <div class="Search-Box" v-if="!this.allSparePartsLoading">
              <input
                type="text"
                v-model="searchSparePart"
                placeholder="Spare Part search..."
              />
              <img
                v-bind:class="{
                  LoadingImg: this.searchLoadingSpareParts,
                }"
                src="../../assets/SearchIcon.png"
              />
            </div>

            <table
              class="SpareParts-Table"
              style="width: 90%; margin-top: 40px"
              v-if="!this.allSparePartsLoading"
            >
              <tr style="text-align: center">
                <th class="th">Part Number</th>
                <th class="th" style="width: 30%">Description</th>
                <th class="th">Minimum Balance</th>
                <th class="th">Price €</th>
                <th class="th">Related Machines</th>
                <th class="th">Action</th>
                <th class="th">Action</th>
              </tr>

              <tr style="text-align: center" v-for="item in this.allSpareParts">
                <td class="td" v-if="this.editSparePartId != item.id">
                  {{ item.partNumber }}
                </td>
                <td class="td" v-if="this.editSparePartId == item.id">
                  <input
                    type="text"
                    class="EditSparePart"
                    v-model="editSparePartNumber"
                  />
                </td>

                <td
                  class="td"
                  style="width: 30%"
                  v-if="this.editSparePartId != item.id"
                >
                  {{ item.description }}
                </td>

                <td
                  class="td"
                  style="width: 30%"
                  v-if="this.editSparePartId == item.id"
                >
                  <input
                    type="text"
                    class="EditSparePart"
                    v-model="editSparePartDescription"
                  />
                </td>

                <td class="td" v-if="this.editSparePartId != item.id">
                  {{ item.minimumBalance }}
                </td>
                <td class="td" v-if="this.editSparePartId == item.id">
                  <input
                    type="number"
                    class="EditSparePart"
                    v-model="editSparePartMinimumBalance"
                  />
                </td>

                <td class="td" v-if="this.editSparePartId != item.id">
                  {{ item.price }}
                </td>
                <td class="td" v-if="this.editSparePartId == item.id">
                  <input
                    type="number"
                    class="EditSparePart"
                    v-model="editSparePartPrice"
                  />
                </td>
                <td
                  class="td"
                  style="display: flex; justify-content: center"
                  v-if="this.editSparePartId != item.id"
                >
                  <div class="RelatedMachines">
                    <a
                      v-if="this.loadingBox4 != item.id"
                      @click="this.read_Related_Machines(item.id)"
                      >Related Machines</a
                    >
                    <a v-if="this.loadingBox4 == item.id">Loading...</a>
                    <img
                      @click="this.read_Related_Machines(item.id)"
                      src="../../assets/DownArrow.png"
                    />
                    <div
                      class="Preview-Related-Machines"
                      v-if="item.id == this.previewRelatedMachines.id"
                      v-click-outside="close_Read_Related_Machines"
                    >
                      <p>Related Machines</p>

                      <div>
                        <a
                          v-for="item in this.previewRelatedMachines
                            .machineSparePartRelated"
                        >
                          {{ item.machine.name }}</a
                        >
                      </div>
                    </div>
                  </div>
                </td>
                <td class="td" v-if="this.editSparePartId == item.id">
                  <div class="RelatedMachines">
                    <a @click="this.ToggleEditRelatedMachines(item.id)"
                      >Related Machines</a
                    >
                    <img
                      @click="this.ToggleEditRelatedMachines(item.id)"
                      src="../../assets/DownArrow.png"
                    />

                    <div
                      class="Related-Machines-Box"
                      v-if="this.editSparePartRelatedMachines"
                      v-click-outside="
                        () => (this.editSparePartRelatedMachines = false)
                      "
                    >
                      <div class="Related-Machines-Search-Box">
                        <input
                          type="text"
                          v-model="searchRelatedBox"
                          placeholder="Machine search..."
                        />
                        <img src="../../assets/SearchIcon.png" />
                      </div>
                      <p>Select Machines / Crimp Tools</p>
                      <div class="Related-Machines-List">
                        <a
                          v-if="!this.loadingBox2"
                          v-for="item in this.relatedMachinesArray"
                          @click="selectRelatedMachinesForEdit(item.id)"
                          v-bind:class="{
                            SelectedRelatedMachine:
                              this.editRelatedMachines.includes(item.id),
                          }"
                        >
                          {{ item.name }}</a
                        >
                        <a style="align-self: center" v-if="this.loadingBox2"
                          >Loading...</a
                        >
                      </div>

                      <div class="RelatedMachines-Navigator">
                        <p
                          v-show="this.relatedMachinesCurrentPage != 1"
                          @click="
                            this.listAllMachinesForRelatedBox(
                              this.token,
                              parseInt(this.relatedMachinesCurrentPage) - 1,
                              this.searchRelatedBox
                            )
                          "
                        >
                          &#8592;
                        </p>
                        <p>Pages</p>
                        <input
                          type="number"
                          v-model="relatedMachinesCurrentPage"
                          v-on:keyup.enter="
                            manuallyChangePageRelatedMachines(
                              token,
                              this.relatedMachinesCurrentPage,
                              this.machinesTotalPages,
                              this.searchRelatedBox
                            )
                          "
                        />
                        <p>of {{ this.relatedMachinesTotalPages }}</p>
                        <p
                          v-show="
                            this.relatedMachinesCurrentPage !=
                            this.relatedMachinesTotalPages
                          "
                          @click="
                            this.listAllMachinesForRelatedBox(
                              this.token,
                              parseInt(this.relatedMachinesCurrentPage) + 1,
                              this.searchRelatedBox
                            )
                          "
                        >
                          &#8594;
                        </p>
                      </div>
                    </div>
                  </div>
                </td>

                <td class="td" v-if="this.editSparePartId != item.id">
                  <img
                    src="../../assets/Edit.png"
                    class="Edit-Icon"
                    @click="
                      choseSparePartToEdit(
                        item.id,
                        item.partNumber,
                        item.minimumBalance,
                        item.price,
                        item.description
                      )
                    "
                  />
                </td>
                <td class="td" v-if="editSparePartId == item.id">
                  <img
                    @click="denySparePartEdit"
                    src="../../assets/Deny.png"
                    class="Edit-Icon"
                  />
                  <img
                    @click="this.confirm_Spare_Part_Edit(item.id)"
                    src="../../assets/Confirm.png"
                    class="Edit-Icon"
                  />
                </td>
                <td class="td">
                  <button
                    class="Delete-Button"
                    @click="deleteSparePart(item.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </table>

            <div class="Bottom-Navigator" v-if="!this.allSparePartsLoading">
              <p
                v-show="this.sparePartsCurrentPage > 1"
                @click="
                  this.listAllSpareParts(
                    this.token,
                    parseInt(this.sparePartsCurrentPage) - 1,
                    this.searchSparePart
                  )
                "
              >
                &#8592;
              </p>
              <p>Pages</p>
              <input
                type="number"
                v-model="sparePartsCurrentPage"
                v-on:keyup.enter="
                  manuallyChangePageSpareParts(
                    token,
                    this.sparePartsCurrentPage,
                    this.sparePartsTotalPages,
                    this.searchSparePart
                  )
                "
              />
              <p>of {{ this.sparePartsTotalPages }}</p>
              <p
                v-show="this.sparePartsCurrentPage < this.sparePartsTotalPages"
                @click="
                  this.listAllSpareParts(
                    this.token,
                    parseInt(this.sparePartsCurrentPage) + 1,
                    this.searchSparePart
                  )
                "
              >
                &#8594;
              </p>
            </div>
          </div>
        </div>

        <!-- CONFIRM DELETE SCREEN -->
        <div
          class="Confirm-Screen"
          v-if="confirmScreen"
          v-click-outside="deleteDecline"
        >
          <p>Delete Spare Part from database</p>
          <p>Are you sure you want to permanently remove this item ?</p>

          <div class="Buttons">
            <button @click="deleteDecline">Decline</button>

            <button
              v-if="
                this.machineDeleteId.length != 0 &&
                this.sparePartDeleteId.length == 0
              "
              @click="deleteMachineConfirm(this.machineDeleteId, this.token)"
            >
              Confirm
            </button>
            <button
              v-if="
                this.sparePartDeleteId.length != 0 &&
                this.machineDeleteId.length == 0
              "
              @click="
                deleteSparePartConfirm(this.sparePartDeleteId, this.token)
              "
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import listAllMachines from "../../api/ListAllMachines";
import listAllMachinesType from "../../api/ListAllMachinesType";
import createNewMachine from "../../api/CreateNewMachine";
import updateMachine from "../../api/UpdateMachine";
import listAllSpareParts from "../../api/ListAllSpareParts";
import createNewSparePart from "../../api/CreateNewSparePart";
import { notify } from "@kyvg/vue3-notification";
import deleteSparePart from "../../api/DeleteSparePart";
import deleteMachine from "../../api/DeleteMachine";
import listSparePartDetails from "../../api/ListSparePartDetails";
import ListSparePartDetails from "../../api/ListSparePartDetails";
import UpdateSparePart from "../../api/UpdateSparePart";

export default {
  name: "Management",
  data() {
    return {
      token: "", // Token
      companyId: 0, // Company ID
      relatedMachines: false, // Show related machines box ( Box 2)
      allMachines: [], // allMachines Box 3
      machinesCurrentPage: 0, // Box3 Trenutna stranica
      machinesTotalPages: 0, // Box3 Total broj stranica
      allMachinesLoading: false, // Third Box Loading
      allMachinesType: [], // List of all types of machines
      newMachineName: "", // First Box - Name
      newMachineDescription: "", // First Box - Name
      newMachineType: "", // First Box - Type
      newMachineLoading: false, // Box1 Dugmic loading
      newMachineInputError: false, // Box1 Ime masine Error
      newMachineDescriptionError: false,
      newMachineTypeError: false, // Box1 Tip masine Error
      editMachineId: null, // Id masine za koju je otvoren edit
      editMachineName: "", // Edit machine name Box 3
      editMachineType: "", // Edit machine type box 3
      editMachineDescription: "", // Edit machine type box 3
      allSparePartsLoading: false, // Loading Box 4
      sparePartsCurrentPage: 0, // Box4 Trenutna stranica
      sparePartsTotalPages: 0, // Box4 Total broj stranica
      allSpareParts: [], // Lista svih Spare Parts - Box 4
      newSparePart: "", // Second Box - Part Number
      newSparePartMinimumBalance: "", // Second Box - Minimum Balance
      newSparePartPrice: "", // Second Box - Part price
      newSparePartDescription: "", // Second Box - Part description
      newSparePartRelatedMachines: [], // Second Box - Related Machines - Array
      newSparePartStock: "", // Second Box - Part stock
      newSparePartLocation: "", // Second Box - Part Location
      newSparePartLoading: false,
      newSparePartError: false,
      newSparePartMinimumBalanceError: false,
      newSparePartPriceError: false,
      newSparePartDescriptionError: false,
      newSparePartRelatedMachinesError: false,
      relatedMachinesArray: [], // all related machines in Box 2
      relatedMachinesCurrentPage: 0,
      relatedMachinesTotalPages: 0,
      confirmScreen: false,
      sparePartDeleteId: "",
      machineDeleteId: "",
      editSparePartId: null,
      editSparePartNumber: "",
      editSparePartMinimumBalance: "",
      editSparePartPrice: "",
      editSparePartDescription: "",
      editRelatedMachines: [],
      editMachineError: false,
      editMachineDescriptionError: false,
      editSparePartRelatedMachines: false,
      previewRelatedMachines: [],
      loadingBox4: null,
      loadingBox2: false,
      searchMachine: "",
      searchSparePart: "",
      searchRelatedBox: "",
      searchLoadingMachine: false,
      searchLoadingSpareParts: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {},
  async created() {
    this.token = localStorage.getItem("Token");
    let temp = localStorage.getItem("Company");
    temp = JSON.parse(temp);
    this.companyId = temp.id;
    this.listAllMachinesType(this.token);
    this.listAllMachines(this.token, 1, this.searchMachine);
    this.listAllSpareParts(this.token, 1, this.searchSparePart);
  },

  watch: {
    // whenever question changes, this function will run
    searchMachine(searchMachine, oldSearchName) {
      this.listAllMachines(this.token, 1, searchMachine);
    },

    searchSparePart(searchSparePart, oldSearchName) {
      this.listAllSpareParts(this.token, 1, searchSparePart);
    },

    searchRelatedBox(searchRelatedBox, oldSearchName) {
      this.listAllMachinesForRelatedBox(this.token, 1, searchRelatedBox);
    },
  },

  methods: {
    //Done
    toggleRelatedMachines() {
      this.editSparePartId = null;
      this.relatedMachines = !this.relatedMachines;
      this.searchRelatedBox = "";
      if (this.relatedMachines) {
        this.listAllMachinesForRelatedBox(this.token, 1, this.searchRelatedBox);
      }
    },

    async read_Related_Machines(id) {
      this.loadingBox4 = id;
      this.editSparePartId = null;
      if (this.previewRelatedMachines.length != 0) {
        this.close_Read_Related_Machines();
      } else {
        await listSparePartDetails
          .List(this.token, id)
          .then((res) => {
            this.previewRelatedMachines = res.data.data;
            this.loadingBox4 = null;
          })
          .catch((err) => {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          });
      }
    },

    close_Read_Related_Machines() {
      this.previewRelatedMachines = [];
      this.loadingBox4 = null;
    },
    //Done
    hideRelatedMachines() {
      this.relatedMachines = false;
    },

    //Done
    async listAllMachines(token, page, searchMachine) {
      if (searchMachine.length == 0) {
        this.allMachinesLoading = true;
      } else {
        this.searchLoadingMachine = true;
      }

      await listAllMachines
        .List(token, page, this.companyId, searchMachine)
        .then((res) => {
          this.allMachines = res.data.machines;
          this.machinesCurrentPage = res.data.currentPage;
          this.machinesTotalPages = res.data.totalPages;
          this.allMachinesLoading = false;
          this.searchLoadingMachine = false;
          let container = document.querySelector(".Content");
          container.scrollTop = 0;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    //Done
    async listAllMachinesForRelatedBox(token, page, searchMachine) {
      this.loadingBox2 = true;
      await listAllMachines
        .List(token, page, this.companyId, searchMachine)
        .then((res) => {
          this.relatedMachinesArray = res.data.machines;
          this.relatedMachinesCurrentPage = page;
          this.relatedMachinesTotalPages = res.data.totalPages;
          this.loadingBox2 = false;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    //Done
    async listAllMachinesType(token) {
      await listAllMachinesType
        .List(token)
        .then((res) => {
          this.allMachinesType = res.data.data;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    //Done
    async createNewMachine(token, name, description, machineTypeId) {
      this.newMachineLoading = true;
      if (name.length == 0) {
        this.newMachineInputError = true;
        this.newMachineLoading = false;
      } else if (description.length == 0) {
        this.newMachineDescriptionError = true;
        this.newMachineInputError = false;
        this.newMachineLoading = false;
      } else if (machineTypeId.length == 0) {
        this.newMachineTypeError = true;
        this.newMachineInputError = false;
        this.newMachineDescriptionError = false;
        this.newMachineLoading = false;
      } else {
        this.newMachineInputError = false;
        this.newMachineTypeError = false;
        this.newMachineDescriptionError = false;
        await createNewMachine
          .Create(token, name, description, machineTypeId, this.companyId)
          .then((res) => {
            if (res.data.success == true) {
              (this.newMachineName = ""),
                (this.newMachineDescription = ""),
                (this.newMachineType = ""),
                notify({
                  title: "Successfully created new Machine !",
                });
              this.listAllMachines(this.token, 1, this.searchMachine);
            }
          })
          .catch((err) => {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          });
        this.newMachineLoading = false;
      }
    },

    manuallyChangePage(token, page, totalPages, searchMachine) {
      let tempPage = page;
      if (tempPage < 1) {
        tempPage = 1;
      } else if (tempPage > totalPages) {
        tempPage = totalPages;
      }
      this.listAllMachines(token, tempPage, searchMachine);
    },

    //Done
    manuallyChangePageSpareParts(token, page, totalPages, searchMachine) {
      let tempPage = page;
      if (tempPage < 1) {
        tempPage = 1;
      } else if (tempPage > totalPages) {
        tempPage = totalPages;
      }
      this.listAllSpareParts(token, tempPage, searchMachine);
    },

    manuallyChangePageRelatedMachines(token, page, totalPages) {
      let tempPage = page;
      if (tempPage < 1) {
        tempPage = 1;
      } else if (tempPage > totalPages) {
        tempPage = totalPages;
      }
      this.listAllMachinesForRelatedBox(token, tempPage, this.searchRelatedBox);
    },

    choseMachineToEdit(id, name, type, description) {
      this.editMachineId = id;
      this.editMachineName = name;
      this.editMachineType = type;
      this.editMachineDescription = description;
    },

    //Done
    denyMachineEdit() {
      this.editMachineId = null;
      this.editMachineName = "";
      this.editMachineType = "";
      this.editMachineDescription = "";
    },

    async updateMachine(id, name, machineTypeId, description, token) {
      if (name.length == 0) {
        this.editMachineError = true;
        return;
      }

      if (description.length == 0) {
        this.editMachineError = false;
        this.editMachineDescriptionError = true;
        return;
      }

      await updateMachine
        .Update(id, name, machineTypeId, description, token)
        .then((res) => {
          if (res.data.success) {
            this.editMachineId = null;
            this.editMachineError = false;
            this.editMachineDescriptionError = false;
            this.editMachineName = "";
            this.editMachineType = "";
            this.editMachineDescription = "";
            notify({
              title: "Successfully Edited Machine !",
            });
            this.listAllMachines(
              this.token,
              this.machinesCurrentPage,
              this.searchMachine
            );
          }
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    //Done
    async listAllSpareParts(token, page, searchSparePart) {
      if (searchSparePart.length == 0) {
        this.allSparePartsLoading = true;
      } else {
        this.searchLoadingSpareParts = true;
      }

      await listAllSpareParts
        .List(token, page, this.companyId, searchSparePart)
        .then((res) => {
          this.allSparePartsLoading = false;
          this.searchLoadingSpareParts = false;
          this.sparePartsCurrentPage = res.data.pagination.currentPage;
          this.sparePartsTotalPages = res.data.pagination.totalPages;
          this.allSpareParts = res.data.data;
          let container = document.querySelector(".Content");
          container.scrollTop = 0;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    //Done
    async createNewSparePart(
      partNumber,
      minimumBalance,
      price,
      machines,
      description,
      location,
      onStock,
      token
    ) {
      this.newSparePartLoading = true;
      if (partNumber.length == 0) {
        this.newSparePartError = true;
        this.newSparePartMinimumBalanceError = false;
        this.newSparePartPriceError = false;
        this.newSparePartRelatedMachinesError = false;
        this.newSparePartLoading = false;
        this.newSparePartDescriptionError = false;
      } else if (minimumBalance.length == 0) {
        this.newSparePartError = false;
        this.newSparePartMinimumBalanceError = true;
        this.newSparePartPriceError = false;
        this.newSparePartRelatedMachinesError = false;
        this.newSparePartLoading = false;
        this.newSparePartDescriptionError = false;
      } else if (price.length == 0) {
        this.newSparePartError = false;
        this.newSparePartMinimumBalanceError = false;
        this.newSparePartPriceError = true;
        this.newSparePartRelatedMachinesError = false;
        this.newSparePartLoading = false;
        this.newSparePartDescriptionError = false;
      } else if (machines.length == 0) {
        this.newSparePartError = false;
        this.newSparePartMinimumBalanceError = false;
        this.newSparePartPriceError = false;
        this.newSparePartRelatedMachinesError = true;
        this.newSparePartLoading = false;
        this.newSparePartDescriptionError = false;
      } else if (description.length == 0) {
        this.newSparePartError = false;
        this.newSparePartMinimumBalanceError = false;
        this.newSparePartPriceError = false;
        this.newSparePartRelatedMachinesError = true;
        this.newSparePartLoading = false;
        this.newSparePartDescriptionError = true;
      } else {
        this.newSparePartError = false;
        this.newSparePartMinimumBalanceError = false;
        this.newSparePartPriceError = false;
        this.newSparePartRelatedMachinesError = false;
        this.newSparePartDescriptionError = false;

        if (onStock.length == 0) onStock = 0;
        if (location.length == 0) location = "null";

        await createNewSparePart
          .Create(
            partNumber,
            minimumBalance,
            price,
            machines,
            description,
            location,
            onStock,
            token,
            this.companyId
          )
          .then((res) => {
            this.newSparePart = "";
            this.newSparePartMinimumBalance = "";
            this.newSparePartPrice = "";
            this.newSparePartLocation = "";
            this.newSparePartStock = "";
            this.newSparePartRelatedMachines = [];
            this.newSparePartLoading = false;
            notify({
              title: "Successfully created new Spare Part !",
            });
            this.listAllSpareParts(this.token, 1, this.searchSparePart);
          })
          .catch((err) => {
            this.newSparePartLoading = false;
            if (err.message == "Request failed with status code 400") {
              notify({
                title: "Spare Part already exist !",
                type: "Error",
              });
            } else {
              this.token = localStorage.removeItem("Token");
              this.$router.push("/");
            }
          });
      }
    },

    //Done
    selectRelatedMachines(id) {
      if (!this.newSparePartRelatedMachines.includes(id)) {
        this.newSparePartRelatedMachines.push(id);
      } else if (this.newSparePartRelatedMachines.includes(id)) {
        this.newSparePartRelatedMachines =
          this.newSparePartRelatedMachines.filter((item) => item !== id);
      }
    },

    selectRelatedMachinesForEdit(id) {
      if (!this.editRelatedMachines.includes(id)) {
        this.editRelatedMachines.push(id);
      } else if (this.editRelatedMachines.includes(id)) {
        this.editRelatedMachines = this.editRelatedMachines.filter(
          (item) => item !== id
        );
      }
    },

    //Done
    deleteSparePart(id) {
      this.sparePartDeleteId = id;
      this.confirmScreen = true;
    },

    //Done
    deleteMachine(id) {
      this.machineDeleteId = id;
      this.confirmScreen = true;
    },

    //Done
    deleteDecline() {
      this.sparePartDeleteId = "";
      this.machineDeleteId = "";
      this.confirmScreen = false;
    },

    async deleteMachineConfirm(id, token) {
      this.allMachinesLoading = true;
      this.confirmScreen = false;
      this.machineDeleteId = "";
      this.sparePartDeleteId = "";
      await deleteMachine
        .Delete(id, token)
        .then((res) => {
          if (res.data.success) {
            notify({
              title: "Machine successfully deleted !",
            });
            this.listAllMachines(this.token, 1, this.searchMachine);
          }
        })
        .catch((err) => {
          if (err.response.data.message == "Unable to delete machine") {
            notify({
              title:
                "Unable to delete machine ! First you have to unhook the spare parts !",
              type: "Error",
            });
            this.listAllMachines(this.token, 1, this.searchMachine);
          } else {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          }
        });
    },

    async deleteSparePartConfirm(id, token) {
      this.allSparePartsLoading = true;
      this.confirmScreen = false;
      this.machineDeleteId = "";
      this.sparePartDeleteId = "";
      await deleteSparePart
        .Delete(id, token)
        .then((res) => {
          if (res.statusText == "OK") {
            notify({
              title: "Successfully deleted Spare Part !",
            });
            this.listAllSpareParts(this.token, 1, this.searchSparePart);
          }
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    choseSparePartToEdit(
      id,
      partNumber,
      minimumBalance,
      price,
      description,
      item
    ) {
      (this.previewRelatedMachines = []), (this.editRelatedMachines = []);
      (this.loadingBox4 = null), (this.editSparePartRelatedMachines = false);
      this.editSparePartId = id;
      this.editSparePartNumber = partNumber;
      this.editSparePartMinimumBalance = minimumBalance;
      this.editSparePartPrice = price;
      this.editSparePartDescription = description;
    },

    async ToggleEditRelatedMachines(id) {
      this.editRelatedMachines = [];
      this.editSparePartRelatedMachines = !this.editSparePartRelatedMachines;
      if (this.editSparePartRelatedMachines) {
        this.loadingBox2 = true;
        this.searchRelatedBox = "";
        await this.listAllMachinesForRelatedBox(
          this.token,
          1,
          this.searchRelatedBox
        );
        await ListSparePartDetails.List(this.token, id)
          .then((res) => {
            let temp = res.data.data.machineSparePartRelated;

            for (let i = 0; i < temp.length; i++) {
              this.editRelatedMachines.push(temp[i].machine.id);
            }
            this.loadingBox2 = false;
          })
          .catch(() => {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          });
      }
    },

    denySparePartEdit() {
      this.editSparePartId = null;
      this.editSparePartNumber = "";
      this.editSparePartMinimumBalance = "";
      this.editSparePartPrice = "";
      this.editRelatedMachines = [];
    },

    async confirm_Spare_Part_Edit(id) {
      if (this.editSparePartNumber.length == 0) {
        notify({
          title: "Invalid Part Number !",
          type: "Error",
        });
        return;
      }
      if (this.editSparePartMinimumBalance.length == 0) {
        notify({
          title: "Invalid Minimum Balance !",
          type: "Error",
        });
        return;
      }
      if (this.editSparePartPrice.length == 0) {
        notify({
          title: "Invalid Price !",
          type: "Error",
        });
        return;
      }
      if (this.editSparePartDescription.length == 0) {
        notify({
          title: "Invalid Description !",
          type: "Error",
        });
        return;
      }
      await UpdateSparePart.Update(
        id,
        this.editSparePartNumber,
        this.editSparePartMinimumBalance,
        this.editSparePartPrice,
        this.editRelatedMachines,
        this.editSparePartDescription,
        this.companyId,
        this.token
      )
        .then((res) => {
          if (res.data.success) {
            this.editSparePartId = null;
            this.editSparePartNumber = "";
            this.editSparePartMinimumBalance = "";
            this.editSparePartPrice = "";
            this.editSparePartDescription = "";
            this.editRelatedMachines = [];
            notify({
              title: "Successfully Edited Spare Part !",
            });
            this.listAllSpareParts(
              this.token,
              this.sparePartsCurrentPage,
              this.searchSparePart
            );
          }
        })
        .catch((err) => {
          if (
            err.response.data.message ==
            "Spare part with this part number already exists"
          ) {
            notify({
              title: "New Part Number already exist !",
              type: "Error",
            });
          } else {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          }
        });
    },
  },
};
</script>

<style scoped>
.Management {
  width: 100%;
  margin-top: 55px;
  height: calc(100vh - 55px);
}

.Management-Header {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
}

.Management-Header > a {
  font-family: Roboto;
  font-size: 15px;
  color: #0097b8;
  font-weight: bold;
  padding-bottom: -20px;
}

.Full-Screen {
  max-width: 1600px;
}

.Content {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.RelatedMachines-Navigator {
  height: 30px;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  left: 0;
  right: 0;
}

.RelatedMachines-Navigator > p {
  font-family: Roboto;
  font-size: 11px;
  color: #8f8f8f;
  margin-left: 4px;
  margin-right: 4px;
}

.RelatedMachines-Navigator > input {
  width: 15px;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 11px;
  color: #8f8f8f;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.RelatedMachines-Navigator > p:nth-child(1) {
  cursor: pointer;
}

.RelatedMachines-Navigator > p:nth-child(5) {
  cursor: pointer;
}

.Bottom-Navigator {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Bottom-Navigator > p {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  margin-left: 4px;
  margin-right: 4px;
}

.Bottom-Navigator > input {
  width: 15px;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Bottom-Navigator > p:nth-child(1) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(5) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(1):hover {
  font-size: 14px;
}

.Bottom-Navigator > p:nth-child(5):hover {
  font-size: 14px;
}

.First-Row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 50px;
}

.Second-Row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 20px;
}

.Box-One {
  width: 500px;
  height: 100px;
  background-color: #f0f2f5;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 15px;
}

.Box-One > p {
  font-family: Roboto;
  font-size: 14px;
  color: #696969;
  font-weight: bold;
  margin-left: 28px;
  line-height: 14px;
}

.Box-One > div {
  display: flex;
  justify-content: space-evenly;
}

.Box-One > div > label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
  padding-left: 15px;
  margin-top: 17px;
}

.Box-One > div > label:nth-child(2){
  margin-left: 105px;
}

.Box-One > div > input {
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(218, 218, 218);
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  line-height: 20px;
}

.Box-Two > div > .Part-Number > .newSparePartError {
  box-shadow: 0.5px 0.5px 5px 0.5px rgb(238, 19, 19);
}

.Box-Two > div > .Minimum-Balance > .newSparePartError {
  box-shadow: 0.5px 0.5px 5px 0.5px rgb(238, 19, 19);
}

.Box-Two > div > .Price > .newSparePartError {
  box-shadow: 0.5px 0.5px 5px 0.5px rgb(238, 19, 19);
}

.Box-One > div > select {
  background-color: white;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
  height: 20px;
  background-color: #f9f9f9;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  cursor: pointer;
}

.editableSelectMachine {
  background-color: white;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
  height: 20px;
  background-color: #f9f9f9;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  cursor: pointer;
}

.Box-One > div > .newMachineNameError {
  box-shadow: 0.5px 0.5px 5px 0.5px rgb(238, 19, 19);
}

.Box-One > div > .newMachineDescriptionError {
  box-shadow: 0.5px 0.5px 5px 0.5px rgb(238, 19, 19);
}

.Box-One > div > .newMachineTypeError {
  box-shadow: 0.5px 0.5px 5px 0.5px rgb(238, 19, 19);
}

.Box-One > div > button {
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 12px;
  width: 60px;
  height: 20px;
  cursor: pointer;
  color: white;
  background-color: #0097b8;
}

.Box-One > div > button > img {
  height: 20px;
}

.Box-One > div > button:hover {
  background-color: #02a6ca;
}

.Box-One > div > button:active {
  background-color: #0286a3;
}

.Box-Two {
  width: 900px;
  height: 100px;
  background-color: #f0f2f5;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 15px;
}

.Box-Two > p {
  font-family: Roboto;
  font-size: 14px;
  color: #696969;
  font-weight: bold;
  margin-left: 28px;
  line-height: 14px;
}

.Box-Two > div {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.Box-Two > div > .Part-Number {
  display: flex;
  flex-direction: column;
}

.Box-Two > div > .Part-Number > label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
}

.Box-Two > div > .Part-Number > input {
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(218, 218, 218);
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  width: 80px;
}

.Box-Two > div > .Minimum-Balance {
  display: flex;
  flex-direction: column;
}

.Box-Two > div > .Minimum-Balance > label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
}

.Box-Two > div > .Minimum-Balance > input {
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(218, 218, 218);
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  width: 110px;
}

.Box-Two > div > .Description {
  display: flex;
  flex-direction: column;
}

.Box-Two > div > .Description > label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
}

.Box-Two > div > .Description > input {
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(218, 218, 218);
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  width: 200px;
}

.Box-Two > div > .Price {
  display: flex;
  flex-direction: column;
}

.Box-Two > div > .Price > label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
}

.Box-Two > div > .Price > input {
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(218, 218, 218);
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  width: 40px;
}

.Box-Two > div > .Location {
  display: flex;
  flex-direction: column;
}

.Box-Two > div > .Location > label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
}

.Box-Two > div > .Location > input {
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(218, 218, 218);
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  width: 50px;
}

.Box-Two > div > .Stock {
  display: flex;
  flex-direction: column;
}

.Box-Two > div > .Stock > label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
}

.Box-Two > div > .Stock > input {
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(218, 218, 218);
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  width: 40px;
}

.Box-Two > div > .RelatedMachines {
  height: 20px;
  background-color: #f9f9f9;
  width: 130px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  align-self: flex-end;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.Box-Two > div > button > img {
  height: 20px;
}

.Box-Two > div > .RelatedMachinesError {
  height: 20px;
  background-color: #f9f9f9;
  width: 130px;
  box-shadow: 0.5px 0.5px 5px 0.5px rgb(238, 19, 19);
  border-radius: 4px;
  align-self: flex-end;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.Box-Two > div > .RelatedMachinesError:hover {
  background-color: #f0f0f0;
}

.Box-Two > div > .RelatedMachinesError > a {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  align-self: center;
  padding-left: 5px;
}

.Box-Two > div > .RelatedMachinesError > img {
  height: 20px;
  padding-right: 3px;
}

.Box-Two > div > .RelatedMachines:hover {
  background-color: #e9e9e9;
}

.Box-Two > div > .RelatedMachines > a {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  align-self: center;
  padding-left: 5px;
  width: calc(100% - 20px);
}

.Box-Two > div > .RelatedMachines > img {
  height: 20px;
  padding-right: 3px;
}

.Box-Two > div > button {
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 12px;
  cursor: pointer;
  width: 60px;
  height: 20px;
  align-self: flex-end;
  margin-bottom: 2px;
  color: white;
  background-color: #0097b8;
}

.Box-Two > div > button:hover {
  background-color: #02a6ca;
}

.Box-Two > div > button:active {
  background-color: #0286a3;
}

.Related-Machines-Box {
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  height: max-content;
  max-height: 500px;
  width: 200px;
  margin-top: 20px;
  cursor: default;
  position: absolute;
  z-index: 2;
  overflow: auto;
}

.Related-Machines-Box > .Related-Machines-List {
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  overflow: auto;
  max-height: 375px;
}

.Related-Machines-Box > p {
  font-family: Roboto;
  font-size: 12px;
  color: #000000;
  font-weight: bold;
  padding-left: 10px;
  text-align: start;
}

.Related-Machines-Box > .Related-Machines-List > a {
  font-family: Roboto;
  font-size: 12px;
  color: #a7a7a7;
  margin-top: 7px;
  cursor: pointer;
  padding-left: 10px;
  text-align: start;
  padding-top: 2px;
  padding-bottom: 2px;
}

.Related-Machines-Box > .Related-Machines-List > .SelectedRelatedMachine {
  font-family: Roboto;
  font-size: 12px;
  color: white;
  margin-top: 7px;
  cursor: pointer;
  background-color: #0097b8;
}

.Related-Machines-Box > .Related-Machines-List > a:hover {
  background-color: #d3d3d3;
}

.Related-Machines-Box > .Related-Machines-List > a:active {
  background-color: #dae2e6;
}

.BoxThree {
  width: 500px;
  height: max-content;
  background-color: #f0f2f5;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoadingBoxThree {
  width: 500px;
  min-height: 200px;
  max-height: 500px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  animation: gradient 1.3s ease-in-out infinite;
  background: linear-gradient(-60deg, #e9e9e9, #f5f5f5, #d4d4d4, #d6d6d6);
  background-size: 400% 400%;
}

.BoxThree > .Search-Box {
  margin-top: 15px;
  width: 200px;
  height: 25px;
  display: flex;
  border-radius: 4px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.BoxThree > .Search-Box > input {
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  color: #a7a7a7;
}

.BoxThree > .Search-Box > img {
  height: 15px;
}

.BoxThree > table {
  width: 90%;
  margin-bottom: 10px;
  margin-top: 40px;
}

.th {
  font-family: Roboto;
  font-size: 13px;
  color: rgb(71, 71, 71);
}

.td {
  font-family: Roboto;
  font-size: 12px;
  border-bottom: 1px solid rgb(218, 218, 218);
  padding-top: 4px;
  padding-bottom: 4px;
  color: rgb(71, 71, 71);
}

.EditMachineName {
  font-family: Roboto;
  font-size: 12px;
  color: #aaaaaa;
  width: 100%;
  outline: none;
  border: 1px solid rgb(218, 218, 218);
  margin-top: 2px;
  width: auto;
  text-align: center;
}

.EditMachineNameError {
  font-family: Roboto;
  font-size: 12px;
  color: #aaaaaa;
  width: 100%;
  outline: none;
  border: unset;
  box-shadow: 0.5px 0.5px 5px 0.5px rgb(238, 19, 19);
  margin-top: 2px;
  width: auto;
  text-align: center;
}

.Edit-Icon {
  width: 18px;
  cursor: pointer;
}

.Edit-Icon:hover {
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 5px;
}

.Delete-Button {
  width: 50px;
  height: 17px;
  background-color: #dae2e6;
  outline: none;
  border: none;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  font-family: Roboto;
  font-size: 12px;
  line-height: 5px;
  cursor: pointer;
  border-radius: 2px;
  color: #494949;
}

.Delete-Button:hover {
  background-color: #ccd0d3;
}

.Delete-Button:active {
  background-color: #dae2e6;
}

.BoxFour {
  width: 900px;
  height: max-content;
  background-color: #f0f2f5;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoadingBoxFour {
  width: 900px;
  min-height: 200px;
  max-height: 500px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  animation: gradient 1.3s ease-in-out infinite;
  background: linear-gradient(-60deg, #e9e9e9, #f5f5f5, #d4d4d4, #d6d6d6);
  background-size: 400% 400%;
}

.BoxFour > .Search-Box {
  margin-top: 15px;
  width: 200px;
  height: 25px;
  display: flex;
  border-radius: 4px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.BoxFour > .Search-Box > input {
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  color: #a7a7a7;
}

.BoxFour > .Search-Box > img {
  height: 15px;
}

.Related-Machines-Search-Box {
  margin-top: 15px;
  width: 90%;
  margin-left: 5%;
  height: 20px;
  display: flex;
  border-radius: 4px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  margin-bottom: 20px;
}

.Related-Machines-Search-Box > input {
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 12px;
  color: #a7a7a7;
}

.Related-Machines-Search-Box > img {
  height: 12px;
  width: 12px;
}

.td > p {
  line-height: 10px;
}

.Confirm-Screen {
  height: 150px;
  width: 400px;
  background-color: #dcdcdc;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px 3px rgb(190, 190, 190);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0 99999px rgba(73, 73, 73, 0.5);
  border-radius: 3px;
}

.Confirm-Screen > p {
  font-family: Roboto;
  font-weight: 600;
  font-size: 15px;
  color: #0097b8;
}

.Confirm-Screen > p:nth-child(2) {
  font-family: Roboto;
  font-size: 12px;
  color: #858686;
}

.EditSparePart {
  font-family: Roboto;
  font-size: 12px;
  color: #aaaaaa;
  width: 100%;
  outline: none;
  border: 1px solid rgb(218, 218, 218);
  width: auto;
  text-align: center;
}

.Buttons {
  margin-top: 10px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Buttons > button {
  border: none;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 300;
  color: white;
  border-radius: 3px;
  padding: 2px;
  width: 75px;
  height: 20px;
  cursor: pointer;
}

.Buttons > button:nth-child(1) {
  background-color: #696969;
}

.Buttons > button:nth-child(2) {
  background-color: #0097b8;
}

.Buttons > button:nth-child(1):hover {
  background-color: #505050;
}

.Buttons > button:nth-child(2):hover {
  background-color: #007e9b;
}

.Buttons > button:nth-child(1):active {
  background-color: #696969;
}

.Buttons > button:nth-child(2):active {
  background-color: #0097b8;
}

.BoxFour > table > tr > td > .RelatedMachines {
  height: 20px;
  background-color: #f9f9f9;
  width: 130px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  align-self: flex-end;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.BoxFour > table > tr > td > .RelatedMachines:hover {
  background-color: #e9e9e9;
}

.BoxFour > table > tr > td > .RelatedMachines > a {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  align-self: center;
  padding-left: 5px;
  width: calc(100% - 20px);
}

.BoxFour > table > tr > td > .RelatedMachines > img {
  height: 20px;
  padding-right: 3px;
}

.Preview-Related-Machines {
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  overflow: auto;
  max-height: 300px;
  width: 200px;
  margin-top: 20px;
  cursor: default;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Preview-Related-Machines > p {
  font-family: Roboto;
  font-size: 12px;
  color: #000000;
  font-weight: bold;
  padding-left: 10px;
}

.Preview-Related-Machines > div {
  overflow: auto;
  max-height: 290px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  padding-bottom: 10px;
}
.Preview-Related-Machines > div > a {
  font-family: Roboto;
  font-size: 12px;
  color: #a7a7a7;
  margin-top: 7px;
  cursor: pointer;
  padding-left: 10px;
  text-align: left;
  margin-bottom: 3px;
  width: calc(100% - 10px);
  padding-top: 2px;
  padding-bottom: 2px;
}

.Preview-Related-Machines > div > a:hover {
  background-color: #d3d3d3;
}

.LoadingImg {
  -webkit-animation: vibrate-1 1s ease-in-out infinite both;
  animation: vibrate-1 1s ease-in-out infinite both;
}

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }
  40% {
    -webkit-transform: translate(-1px, -1px);
    transform: translate(-1px, -1px);
  }
  60% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  80% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }
  40% {
    -webkit-transform: translate(-1px, -1px);
    transform: translate(-1px, -1px);
  }
  60% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  80% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 1420px) {
  .First-Row {
    flex-direction: column;
    margin-left: 20px;
    width: 720px;
  }

  .Box-One > div > label {
    padding-left: 55px;
  }

  .Second-Row {
    flex-direction: column;
    margin-left: 20px;
    width: 720px;
  }

  .Box-One {
    width: 700px;
  }

  .Box-Two {
    margin-top: 50px;
  }

  .BoxThree {
    width: 700px;
  }

  .BoxFour {
    margin-top: 50px;
  }

  .LoadingBoxFour {
    margin-top: 50px;
  }
}

@media screen and (max-width: 635px) {
  .Management-Header {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}
</style>
