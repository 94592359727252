import axios from "axios";

const live = "https://myexcel.app:3000/machines/edit/"
//const url = "http://147.182.172.150:3000/machines/edit/";

class UpdateMachine {
  static Update(id, name, machineTypeId, description, token) {
    return axios.put(
      `${live}${id}`,
      {
        name,
        machineTypeId,
        description
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default UpdateMachine;
