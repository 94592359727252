<template>
  <div class="Machines">
    <div class="Machines-Header">
      <a>Machines / Crimp Tools Overview</a>
      <div class="Search-Box">
        <input
          type="text"
          v-model="searchName"
          placeholder="Machine Search..."
        />
        <img src="../../assets/SearchIcon.png" />
      </div>
    </div>
    <div class="Second-Header"></div>
    <div class="Table-Header">
      <div>
        <p>Name</p>
        <p>Description</p>
        <p>Type</p>
        <p></p>
      </div>
    </div>

    <div class="Content">
      <div class="TableContentLoading" v-if="loadingContent"></div>
      <div class="TableContentLoading" v-if="loadingContent"></div>
      <div class="TableContentLoading" v-if="loadingContent"></div>
      <p
        class="Nothing-To-Show"
        v-if="!loadingContent && machinesList.length == 0"
      >
        Nothing to show
      </p>
      
      <div
        class="Table-Content"
        v-if="!loadingContent && machinesList.length > 0"
        v-for="item in machinesList"
      >
        <div>
          <p>{{ item.name }}</p>
          <p>{{ item.description }}</p>
          <p>{{ item.machineType.name }}</p>
          <img src="../../assets/ViewMore.png" @click="openDetails(item.id)" />
        </div>
        <div class="PartDetails" v-if="openDetailsId == item.id">
          <table class="Machine-Table">
            <tr style="text-align: center; background-color: #0097b8">
              <th class="th">Related Spare Parts</th>
              <th class="th">Description</th>
              <th class="th">Minimum Balance</th>
              <th class="th">On Stock</th>
              <th class="th">On Order</th>
              <th class="th">Location</th>
              <th class="th">Price €</th>
              <th class="th">Critical Part</th>
            </tr>
            <tr style="text-align: center" v-if="this.loadingTable">
              <td colspan="7" class="td" style="border-bottom: none">
                Loading...
              </td>
            </tr>
            <tr
              style="text-align: center"
              v-for="part in machineDetails"
              v-if="!this.loadingTable"
            >
              <td
                v-bind:class="{
                  td: !part.sparePart.criticalPart,
                  tdCritical: part.sparePart.criticalPart,
                }"
              >
                {{ part.sparePart.partNumber }}
              </td>
              <td
                v-bind:class="{
                  td: !part.sparePart.criticalPart,
                  tdCritical: part.sparePart.criticalPart,
                }"
              >
                {{ part.sparePart.description }}
              </td>
              <td
                v-bind:class="{
                  td: !part.sparePart.criticalPart,
                  tdCritical: part.sparePart.criticalPart,
                }"
              >
                {{ part.sparePart.minimumBalance }}
              </td>
              <td
                v-bind:class="{
                  td: !part.sparePart.criticalPart,
                  tdCritical: part.sparePart.criticalPart,
                }"
              >
                {{ part.sparePart.onStock }}
              </td>
              <td
                v-bind:class="{
                  td: !part.sparePart.criticalPart,
                  tdCritical: part.sparePart.criticalPart,
                }"
              >
                {{ part.sparePart.onOrder }}
              </td>
              <td
                v-bind:class="{
                  td: !part.sparePart.criticalPart,
                  tdCritical: part.sparePart.criticalPart,
                }"
              >
                {{ part.sparePart.location }}
              </td>
              <td
                v-bind:class="{
                  td: !part.sparePart.criticalPart,
                  tdCritical: part.sparePart.criticalPart,
                }"
              >
                {{ part.sparePart.price }}
              </td>
              <td
                v-bind:class="{
                  td: !part.sparePart.criticalPart,
                  tdCritical: part.sparePart.criticalPart,
                }"
              >
                {{ part.sparePart.criticalPart }}
              </td>
            </tr>
          </table>
        </div>
      </div>






      <div class="Bottom-Navigator">
        <p
          v-show="this.currentPage != 1 && this.currentPage > 0"
          @click="
            this.listAllMachines(
              this.token,
              parseInt(this.currentPage) - 1,
              this.searchName
            )
          "
        >
          &#8592;
        </p>
        <p>Pages</p>
        <input
          type="number"
          v-model="currentPage"
          v-on:keyup.enter="
            manuallyChangePage(
              token,
              this.currentPage,
              this.totalPages,
              this.searchName
            )
          "
        />
        <p>of {{ this.totalPages }}</p>
        <p
          v-show="this.currentPage < this.totalPages"
          @click="
            this.listAllMachines(
              this.token,
              parseInt(this.currentPage) + 1,
              this.searchName
            )
          "
        >
          &#8594;
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import listAllMachines from "../../api/ListAllMachines";
import listMachineDetails from "../../api/ListMachineDetails";

export default {
  name: "Machines",
  data() {
    return {
      openDetailsId: null, // Detaljni prikaz masine
      loadingContent: false, // Main loading
      token: "", // Token
      companyId: 0, // ID Kompanije
      machinesList: [], // Lista masina za prikaz
      currentPage: 0, // Trenutni page
      totalPages: 0, // Total stranica
      machineDetails: [], // Detalji o izabranoj masini
      loadingTable: false, // Loading table
      searchName: "", // Search input
    };
  },
  async created() {
    this.token = localStorage.getItem("Token");
    let temp = localStorage.getItem("Company");
    temp = JSON.parse(temp);
    this.companyId = temp.id;
    this.listAllMachines(this.token, 1, this.searchName);
  },
  watch: {
    // whenever question changes, this function will run
    searchName(searchName, oldSearchName) {
      this.listAllMachines(this.token, 1, searchName);
    },
  },

  methods: {
    async listAllMachines(token, page, searchName) {
      this.loadingContent = true;
      await listAllMachines
        .List(token, page, this.companyId, searchName)
        .then((res) => {
          this.machinesList = res.data.machines;
          this.currentPage = res.data.currentPage;
          this.totalPages = res.data.totalPages;
          this.loadingContent = false;
          let container = document.querySelector(".Content");
          container.scrollTop = 0;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    async listMachineDetails(token, id) {
      this.loadingTable = true;
      listMachineDetails
        .List(token, id)
        .then((res) => {
          this.machineDetails = res.data.machine.machineSparePartRelated;
          this.loadingTable = false;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    manuallyChangePage(token, page, totalPages, searchName) {
      let tempPage = page;
      if (tempPage < 1) {
        tempPage = 1;
      } else if (tempPage > totalPages) {
        tempPage = totalPages;
      }
      this.listAllMachines(token, tempPage, searchName);
    },

    openDetails(id) {
      if (id == this.openDetailsId) {
        this.openDetailsId = "";
        this.machineDetails = [];
      } else {
        this.openDetailsId = id;
        this.machineDetails = [];
        this.listMachineDetails(this.token, this.openDetailsId);
      }
    },
  },
};
</script>

<style scoped>
.Machines {
  width: 100%;
  margin-top: 55px;
  height: calc(100vh - 55px);
  display: flex;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Nothing-To-Show {
  font-size: 24px;
  align-self: center;
  font-family: Roboto;
  color: #d3cfcf;
  cursor: pointer;
}

.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 10px;
  margin-top: 10px;
}

.Machines-Header {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
}

.Machines-Header > a {
  font-family: Roboto;
  font-size: 15px;
  color: #0097b8;
  font-weight: bold;
  margin-bottom: -20px;
}

.Search-Box {
  width: 250px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.Search-Box > input {
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 13px;
  color: #a7a7a7;
  width: 200px;
}

.Search-Box > img {
  height: 15px;
}

.Second-Header {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.Table-Header {
  background-color: #0097b8;
  width: calc(100% - 100px);
  margin-top: 20px;
  align-self: center;
  border-radius: 4px;
  height: 30px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.Table-Header > div {
  display: flex;
  height: 100%;
  align-items: center;
}

.Table-Header > div > p {
  color: #e7ebf1;
  font-weight: 500;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  margin-left: 70px;
  width: 135px;
  text-align: center;
}

.Table-Header > div > p:last-child {
  width: 30px;
  margin: auto;
  margin-right: 20px;
}

.Table-Content {
  background-color: #f0f2f5;
  width: calc(100% - 100px);
  margin-top: 15px;
  align-self: center;
  border-radius: 4px;
  height: min-content;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.Table-Content > div {
  align-items: center;
  align-self: center;
  display: flex;
  width: 100%;
}

.Table-Content > div > p {
  margin-left: 70px;
  width: 135px;
  text-align: center;
  color: rgb(71, 71, 71);
  font-weight: 400;
  font-family: Roboto;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: 7px;
  word-wrap: break-word;
}

.Table-Content > div > img {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin: auto;
  margin-right: 20px;
}

.Table-Content > div > img:hover {
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 50%;
}

.TableContentLoading {
  background-color: #f0f2f5;
  width: calc(100% - 100px);
  margin-top: 20px;
  height: 28px;
  align-self: center;
  border-radius: 4px;
  display: flex;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  align-items: center;
  align-self: center;
  animation: gradient 1.3s ease-in-out infinite;
  background: linear-gradient(-60deg, #e9e9e9, #f5f5f5, #d4d4d4, #d6d6d6);
  background-size: 400% 400%;
}

.Table-Content > .PartDetails {
  height: min-content;
  position: relative;
}

.Table-Content > .PartDetails > button {
  position: absolute;
  margin: auto;
  bottom: 10px;
  right: 20px;
  width: 50px;
  height: 17px;
  background-color: #dae2e6;
  outline: none;
  border: none;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  font-family: Roboto;
  font-size: 12px;
  color: #8c8c94;
  line-height: 5px;
  cursor: pointer;
  border-radius: 2px;
  font-weight: bold;
}

.Table-Content > .PartDetails > button:nth-child(2) {
  right: 90px;
}

.Table-Content > .PartDetails > button:hover {
  background-color: #ccd0d3;
}

.Table-Content > .PartDetails > button:active {
  background-color: #dae2e6;
}

.th {
  font-family: Roboto;
  font-size: 13px;
  color: white;
  font-weight: 300;
}

.td {
  font-family: Roboto;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 0.5px dashed #8f8f8f;
}

.tdCritical {
  font-family: Roboto;
  font-size: 12px;
  color: #ec2020;
  padding-top: 4px;
  padding-bottom: 4px;
}

.Machine-Table {
  width: 800px;
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.Bottom-Navigator {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.Bottom-Navigator > p {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  margin-left: 4px;
  margin-right: 4px;
}

.Bottom-Navigator > input {
  width: 15px;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
  background-color: transparent;
}

.Bottom-Navigator > p:nth-child(1) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(5) {
  cursor: pointer;
}

.Bottom-Navigator > p:nth-child(1):hover {
  font-size: 14px;
}

.Bottom-Navigator > p:nth-child(5):hover {
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  .Table-Header > div > p {
    margin-left: 20px;
  }

  .Table-Content > div > p {
    margin-left: 20px;
  }
}

@media screen and (max-width: 800px) {
  .Machines-Header {
    margin-left: 2%;
    margin-right: unset;
  }

  .Second-Header {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 70px;
  }

  .Second-Header > button {
    margin-right: 0px;
  }

  .Table-Header {
    width: 96vw;
  }

  .Table-Content {
    width: 96vw;
  }

  .Search-Box {
    margin-top: 30px;
  }

  .Machine-Table {
    width: 96%;
    margin-left: 2%;
  }
}

@media screen and (max-width: 750px) {
  .Table-Header > div > p {
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .Table-Content > div > p {
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 500px) {
  .Table-Header > div > p {
    font-size: 10px;
  }

  .Table-Content > div > p {
    font-size: 10px;
  }

  .Table-Content > div > button {
    font-size: 10px;
    width: 50px;
    height: 15px;
    margin-right: 10px;
  }

  .th {
    font-size: 10px;
  }

  .td {
    font-size: 10px;
  }

  .tdCritical {
    font-size: 10px;
  }
}
</style>
