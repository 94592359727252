<template><router-view />
<notifications position="bottom center" classes="my-notification" /></template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html,
body {
  margin: 0px !important;
  padding: 0px !important;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #302c3c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.my-notification {
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
  color: grey;
  font-family: Roboto;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 1px grey;
  border-left: 3px solid rgb(55, 177, 30);
  }

  .Error {
    border-left: 3px solid red;
  }
</style>
