<template>
  <div class="Upload_File_Parrent">
    <div class="Upload_File" v-click-outside="close">
      <p class="Title">Upload file</p>
      <input
        class="File_Name_Input"
        type="text"
        placeholder="Name"
        v-model="fileName"
      />
      <div class="Upload_Box" @click="trigger_file_input">
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="handle_file_upload"
          accept=".zip"
        />
        <img src="../../assets/choose_file.png" />
        <p>Choose ZIP file</p>
      </div>
      <div class="Notes">
        <p>Choosen file: {{ this.file == null ? "" : this.file.name }}</p>
        <p>Supported format: .zip</p>
      </div>

      <button class="Upload_Button" v-if="!loading" @click="upload">
        Upload
      </button>
      <div class="Uploading_Box" v-else>
        <div class="Info">
          <a>{{ fileName }}</a>
          <a>{{ progress }}%</a>
        </div>
        <progress :value="progress" max="100" class="progress"></progress>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import Upload_Scheme from "../../api/Technology/upload_scheme";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "Upload_Scheme",
  data() {
    return {
      loading: false,
      token: "",
      fileName: "",
      file: null,
      progress: 0,
    };
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  created() {
    this.token = localStorage.getItem("Token");
  },

  methods: {
    close() {
      if (!this.loading) this.$emit("close", "close");
    },

    trigger_file_input() {
      this.$refs.fileInput.click();
    },

    handle_file_upload(event) {
      const file = event.target.files[0];
      if (file == undefined) {
        this.file = null;
      } else {
        this.file = file;
      }
    },

    async upload() {
      if (this.fileName.trim().length == 0) {
        notify({
          title: "Invalid file name !",
          type: "Error",
        });
        return;
      }
      if (this.file == null) {
        notify({
          title: "Invalid file !",
          type: "Error",
        });
        return;
      }
      const uploadProgressHandler = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.progress = percentCompleted;
      };
      this.loading = true;
      await Upload_Scheme.Upload_Scheme(this.fileName, this.file, this.token, {
        onUploadProgress: uploadProgressHandler,
      })
        .then((res) => {
          if (res.data.message == "Files extracted and saved successfully") {
            notify({
              title: "Files extracted and saved successfully !",
              type: "success",
            });
            this.$emit("load");
          }
        })
        .catch((err) => {
          if (
            err.response.data.message ==
            "Record with the same product number already exists in WireList table !"
          ) {
            notify({
              title:
                "Record with the same product number already exists in WireList table !",
              type: "Error",
            });
            this.loading = false;
            return;
          } else {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          }
        });
    },
  },
};
</script>

<style scoped>
.Upload_File_Parrent {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  overflow: auto;
}

.Upload_File {
  width: 500px;
  height: fit-content;
  background-color: white;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  border-radius: 10px;
}

.Title {
  font-size: 18px;
  font-weight: bold;
  font-family: Roboto;
  margin-left: 20px;
  margin-right: 20px;
}

.File_Name_Input {
  width: calc(100% - 57px);
  margin-left: 20px;
  height: 25px;
  outline: none;
  border: unset;
  background-color: #f2f6ff;
  font-size: 15px;
  font-family: Roboto;
  padding-left: 10px;
  padding-right: 10px;
}

.Upload_Box {
  width: calc(100% - 40px);
  height: 180px;
  background-color: #f2f6ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  border: 1px dashed #839fec;
  margin-top: 20px;
}

.Upload_Box:hover {
  background-color: #e4eafa;
}

.Upload_Box > img {
  height: 50px;
  margin-top: 20px;
}

.Upload_Box > p {
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  color: rgb(90, 90, 90);
}

.Notes {
  width: calc(100% - 40px);
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(160, 160, 160);
  font-family: Roboto;
  font-size: 14px;
}

.Upload_Button {
  background-color: #dae2e6;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 17px;
  cursor: pointer;
  align-self: flex-end;
  color: #494949;
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: center;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Upload_Button:hover {
  background-color: #02a6ca;
  color: white;
}

.Upload_Button:active {
  background-color: #0286a3;
  color: white;
}

.Uploading_Box {
  background-color: #dae2e6;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;
}

.Uploading_Box > progress {
  width: 90%;
  margin-left: 5%;
  height: 12px;
  -webkit-appearance: none;
  appearance: none;
}

.Uploading_Box > progress::-webkit-progress-bar {
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.Uploading_Box > progress::-webkit-progress-value {
  background-color: #02a6ca;
  border-radius: 5px;
}

.Uploading_Box > progress::-moz-progress-bar {
  background-color: #02a6ca;
  border-radius: 5px;
}

.Info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(160, 160, 160);
}

.Info > a {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  font-family: Roboto;
  font-size: 14px;
}
</style>
