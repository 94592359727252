import axios from "axios";

const live = "https://myexcel.app:3000/spareParts/"
//const url = "http://147.182.172.150:3000/spareParts/";

class ListSparePartDetails {
  static List(token, id) {
    return axios.get(`${live}${id}`, { headers: { Authorization: `Bearer ${token}` } });
  }
}

export default ListSparePartDetails;
