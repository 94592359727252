import axios from "axios";

const live = "https://myexcel.app:3000/spareParts/addOnOrder"
//const url = "http://147.182.172.150:3000/spareParts/addOnOrder";

class OrderSparePart {
  static Order(sparePartId, onOrder, type, token) {
    return axios.post(
      `${live}`,
      {
        sparePartId,
        onOrder,
        type
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default OrderSparePart;
