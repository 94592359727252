<template>
  <div class="History">
    <div class="History-Header">
      <a>History Reports</a>
    </div>
    <div class="Content">
      <div class="Creator">
        <div>
          <p>Create History Report</p>
        </div>
        <div>
          <div class="Spare-Parts">
            <a @click="toggle_Box">Select Spare Parts</a>
            <img @click="toggle_Box" src="../../assets/DownArrow.png" />

            <div class="Spare-Parts-Box" v-if="this.partsBox" v-click-outside="close_Box">
              <div class="Spare-Parts-Search-Box">
                <input type="text" v-model="searchName" placeholder="Spare Part Search..." />
                <img src="../../assets/SearchIcon.png" />
              </div>

              <p>Select Spare Parts</p>
              <div class="Spare-Parts-List">
                <a v-if="!this.loading" v-for="item in this.allSpareParts" @click="this.select_Spare_Parts(item.id)"
                  v-bind:class="{
                    Selected: this.selectedParts.includes(item.id),
                  }">
                  {{ item.partNumber }}</a>

                <a v-if="this.loading" style="align-self: center">Loading...</a>
              </div>

              <div class="Spare-Parts-Navigator">
                <p v-show="this.currentPageBox != 1 && this.currentPageBox > 0" @click="
                  this.list_All_Spare_Parts(
                    this.token,
                    parseInt(this.currentPageBox) - 1,
                    this.searchName
                  )
                  ">
                  &#8592;
                </p>
                <p>Pages</p>
                <input type="number" v-model="currentPageBox" v-on:keyup.enter="
                  manually_Change_Page_Drop_Box(
                    this.currentPageBox,
                    this.totalPagesBox
                  )
                  " />
                <p>of {{ this.totalPagesBox }}</p>
                <p v-show="this.currentPageBox < this.totalPagesBox" @click="
                  this.list_All_Spare_Parts(
                    this.token,
                    parseInt(this.currentPageBox) + 1,
                    this.searchName
                  )
                  ">
                  &#8594;
                </p>
              </div>
            </div>
          </div>
          <div class="Start-Date">
            <label>Start Date:</label>
            <input type="date" v-model="startDate" />
          </div>
          <div class="End-Date">
            <label>End Date:</label>
            <input type="date" v-model="endDate" />
          </div>

          <button v-if="!this.loadingPreviewButton" @click="
            this.preview(1, this.selectedParts, this.startDate, this.endDate)
            ">
            Preview
          </button>

          <button v-if="this.loadingPreviewButton">
            <img src="../../assets/LoadingButton.gif" />
          </button>

          <button v-if="!this.loadingExportButton" @click="
            this.export_Logs(this.selectedParts, this.startDate, this.endDate)
            ">
            Export <img src="../../assets/Excel.png" />
          </button>
          <button v-if="this.loadingExportButton">
            <img src="../../assets/LoadingButton.gif" />
          </button>
        </div>
      </div>
      <div class="Preview">
        <p>Preview</p>
        <table class="Preview-Table" style="margin-top: 40px">
          <tr style="text-align: center; background-color: #0097b8">
            <th class="th">Part Number</th>
            <th class="th">Date</th>
            <th class="th">Minimum Balance</th>
            <th class="th">On Order</th>
            <th class="th">On Stock</th>
            <th class="th">Location</th>
            <th class="th">Price €</th>
            <th class="th">Critical Part</th>
          </tr>
          <tr style="text-align: center" v-for="item in previewOutput">
            <td class="td">{{ item.partNumber }}</td>
            <td class="td">{{ item.date }}</td>
            <td class="td">{{ item.minimumBalance }}</td>
            <td class="td">{{ item.onOrder }}</td>
            <td class="td">{{ item.onStock }}</td>
            <td class="td">{{ item.location }}</td>
            <td class="td">{{ item.price }}</td>
            <td class="td">{{ item.criticalPart }}</td>
          </tr>
        </table>

        <div class="Bottom-Navigator">
          <p v-show="this.previewCurrentPage != 1 && this.previewCurrentPage > 1" @click="
            this.preview(
              parseInt(this.previewCurrentPage) - 1,
              this.selectedParts,
              this.startDate,
              this.endDate
            )
            ">
            &#8592;
          </p>
          <p>Pages</p>
          <input type="number" v-model="previewCurrentPage" v-on:keyup.enter="
            manually_Change_Page(
              this.previewCurrentPage,
              this.previewTotalPages
            )
            " />
          <p>of {{ this.previewTotalPages }}</p>
          <p v-show="this.previewCurrentPage < this.previewTotalPages" @click="
            this.preview(
              parseInt(this.previewCurrentPage) + 1,
              this.selectedParts,
              this.startDate,
              this.endDate
            )
            ">
            &#8594;
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listAllSpareParts from "../../api/ListAllSpareParts";
import vClickOutside from "click-outside-vue3";
import getLogs from "../../api/getLogs";
import { notify } from "@kyvg/vue3-notification";
import exportLogs from "../../api/exportLogs";
export default {
  name: "History",

  data() {
    return {
      allSpareParts: [], // Lista svih spare partova za drop box
      startDate: "", // Start date
      endDate: "", // End date
      loading: false, //Loading unutar drop box-a
      token: "", // Token
      companyId: 0, // ID Kompanije
      currentPageBox: 0, // Trenutna stranica
      totalPagesBox: 0, // Total broj stranica
      partsBox: false, // Prikaz drop boxa
      selectedParts: [], // Selectovani spare partovi unutar drop boxa
      previewOutput: [], // Preview output
      previewCurrentPage: 0, // Preview trenutna stranica
      previewTotalPages: 0, // Preview Total broj stranica
      loadingPreviewButton: false, // Loading Preview Dugmic
      loadingExportButton: false, // Loading Export Dugmic,
      searchName: "",
    };
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  created() {
    this.token = localStorage.getItem("Token");
    let temp = localStorage.getItem("Company");
    temp = JSON.parse(temp);
    this.companyId = temp.id;
    this.list_All_Spare_Parts(this.token, 1, this.searchName);
  },

  watch: {
    // whenever question changes, this function will run
    searchName(searchName, oldSearchName) {
      this.list_All_Spare_Parts(this.token, 1, searchName);
    },
  },

  methods: {
    async list_All_Spare_Parts(token, page, searchName) {
      this.loading = true;
      await listAllSpareParts
        .List(token, page, this.companyId, searchName)
        .then((res) => {
          this.loading = false;
          this.currentPageBox = res.data.pagination.currentPage;
          this.totalPagesBox = res.data.pagination.totalPages;
          this.allSpareParts = res.data.data;
        })
        .catch((err) => {
          this.token = localStorage.removeItem("Token");
          this.$router.push("/");
        });
    },

    toggle_Box() {
      this.partsBox = !this.partsBox;
    },

    close_Box() {
      this.partsBox = false;
    },

    manually_Change_Page(page, totalPages) {
      let tempPage = page;
      if (tempPage < 1) {
        tempPage = 1;
      } else if (tempPage > totalPages) {
        tempPage = totalPages;
      }
      this.preview(tempPage, this.selectedParts, this.startDate, this.endDate);
    },

    manually_Change_Page_Drop_Box(page, totalPages) {
      let tempPage = page;
      if (tempPage < 1) {
        tempPage = 1;
      } else if (tempPage > totalPages) {
        tempPage = totalPages;
      }
      this.list_All_Spare_Parts(this.token, tempPage, this.searchName);
    },

    select_Spare_Parts(id) {
      if (!this.selectedParts.includes(id)) {
        this.selectedParts.push(id);
      } else if (this.selectedParts.includes(id)) {
        this.selectedParts = this.selectedParts.filter((item) => item !== id);
      }
    },

    async preview(page, sparePartId, startDate, endDate) {
      // if (sparePartId.length == 0) {
      //   notify({
      //     title: "You didn't select any Spare Part !",
      //     type: "Error",
      //   });
      //   return;
      // } else  
      //
      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        notify({
          title: "The start date is greater than the end date !",
          type: "Error",
        });
        return;
      } else {
        if (startDate.length == 0) startDate = "2023-01-01";
        if (endDate.length == 0)
          endDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1
            }-${new Date().getDate()}`;
        this.loadingPreviewButton = true;
        await getLogs
          .Get(page, sparePartId, startDate, endDate, this.token)
          .then((res) => {
            this.previewCurrentPage = res.data.currentPage;
            this.previewTotalPages = res.data.totalPages;
            this.previewOutput = res.data.sparePartsLog;
            if (this.previewOutput.length == 0) {
              notify({
                title: "Can't find any Log for that period !",
                type: "Error",
              });
            }
            for (let i = 0; i < this.previewOutput.length; i++) {
              let date = new Date(this.previewOutput[i].date);
              var day = date.getUTCDate();
              var month = date.getUTCMonth() + 1;
              var year = date.getUTCFullYear();
              this.previewOutput[i].date = `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""
                }${month}-${year}`;
            }
            this.loadingPreviewButton = false;
          })
          .catch((err) => {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          });
      }
    },

    async export_Logs(sparePartId, startDate, endDate) {
      // if (sparePartId.length == 0) {
      //   notify({
      //     title: "You didn't select any Spare Part !",
      //     type: "Error",
      //   });
      //   return;
      // } else 
      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        notify({
          title: "The start date is greater than the end date !",
          type: "Error",
        });
        return;
      } else {
        this.loadingExportButton = true;
        if (startDate.length == 0) startDate = "2023-01-01";
        if (endDate.length == 0)
          endDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1
            }-${new Date().getDate()}`;
        await exportLogs
          .Export(sparePartId, startDate, endDate, this.token)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            var currentdate = new Date();

            var datetime =
              currentdate.getDate() +
              "." +
              (currentdate.getMonth() + 1) +
              "." +
              currentdate.getFullYear() +
              " " +
              currentdate.getHours() +
              "-" +
              currentdate.getMinutes();

            link.setAttribute("download", `Logs ${datetime}.xlsx`);
            document.body.appendChild(link);
            link.click();
            this.loadingExportButton = false;
          })
          .catch((err) => {
            this.token = localStorage.removeItem("Token");
            this.$router.push("/");
          });
      }
    },
  },
};
</script>

<style scoped>
.History {
  width: 100%;
  margin-top: 55px;
  height: calc(100vh - 55px);
}

.History-Header {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
  max-width: 1600px;
}

.History-Header>a {
  font-family: Roboto;
  font-size: 15px;
  color: #0097b8;
  font-weight: bold;
  padding-bottom: -20px;
}

.Content {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.Creator {
  width: 800px;
  height: 100px;
  background-color: #f0f2f5;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
  border-radius: 5px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;
}

.Creator>div>p {
  font-family: Roboto;
  font-size: 14px;
  color: #696969;
  font-weight: bold;
  margin-left: 28px;
  line-height: 10px;
}

.Creator>div:nth-child(2) {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5px;
}

.Spare-Parts {
  height: 20px;
  background-color: #f9f9f9;
  width: 150px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  align-self: flex-end;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.Spare-Parts:hover {
  background-color: #f0f0f0;
}

.Spare-Parts>a {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  align-self: center;
  padding-left: 5px;
  width: calc(100% - 20px);
}

.Spare-Parts>img {
  height: 20px;
  padding-right: 3px;
}

.Start-Date>label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
}

.Start-Date>input {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
  border: none;
  margin-left: 7px;
  outline: none;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  height: 20px;
  background-color: #f9f9f9;
}

.End-Date>label {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
}

.End-Date>input {
  font-family: Roboto;
  font-size: 13px;
  color: #8f8f8f;
  background-color: #f9f9f9;
  border: none;
  margin-left: 7px;
  outline: none;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  border-radius: 4px;
  height: 20px;
}

.Creator>div>button {
  background-color: #dae2e6;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 12px;
  cursor: pointer;
  width: 60px;
  height: 20px;
  align-self: flex-end;
  margin-bottom: 2px;
  color: #494949;
}

.Creator>div>button:nth-child(5) {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.Creator>div>button:nth-child(4) {
  background-color: #0097b8;
  color: white;
}

.Creator>div>button>img {
  height: 20px;
}

.Creator>div>button:hover {
  background-color: #02a6ca;
}

.Creator>div>button:active {
  background-color: #0286a3;
}

.Preview {
  width: 1400px;
  height: fit-content;
  margin-bottom: 10px;
  background-color: #f0f2f5;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
  border-radius: 5px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
}

.Preview>p {
  font-family: Roboto;
  font-size: 14px;
  color: #696969;
  font-weight: bold;
  margin-left: 3%;
  line-height: 10px;
}

.th {
  font-family: Roboto;
  font-size: 13px;
  color: white;
  font-weight: 300;
}

.td {
  font-family: Roboto;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 0.5px dashed #8f8f8f;
}

.Preview-Table {
  width: 94%;
  border-collapse: collapse;
  margin-left: 3%;
}

.Spare-Parts-Box {
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  height: max-content;
  height: fit-content;
  width: 200px;
  margin-top: 20px;
  cursor: default;
  position: absolute;
  z-index: 2;
}

.Spare-Parts-Box>.Spare-Parts-List {
  display: flex;
  flex-direction: column;
  text-align: start;
  overflow: auto;
  max-height: 300px;
}

.Spare-Parts-Box>p {
  font-family: Roboto;
  font-size: 12px;
  color: #000000;
  font-weight: bold;
  padding-left: 10px;
}

.Spare-Parts-Box>.Spare-Parts-List>a {
  font-family: Roboto;
  font-size: 12px;
  margin-top: 7px;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.Spare-Parts-Box>.Spare-Parts-List>a:hover {
  background-color: #d3d3d3;
}

.Spare-Parts-Box>.Spare-Parts-List>a:active {
  background-color: #dae2e6;
}

.Spare-Parts-Search-Box {
  margin-top: 15px;
  width: 90%;
  margin-left: 5%;
  height: 20px;
  display: flex;
  border-radius: 4px;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 3px 5px 2px rgb(190, 190, 190);
  margin-bottom: 20px;
}

.Spare-Parts-Search-Box>input {
  outline: none;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  font-size: 12px;
  color: #a7a7a7;
}

.Spare-Parts-Search-Box>img {
  height: 12px;
  width: 12px;
}

.Spare-Parts-Navigator {
  height: 30px;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  left: 0;
  right: 0;
  padding-top: 10px;
}

.Spare-Parts-Navigator>p {
  font-family: Roboto;
  font-size: 11px;
  color: #8f8f8f;
  margin-left: 4px;
  margin-right: 4px;
}

.Spare-Parts-Navigator>input {
  width: 15px;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 11px;
  color: #8f8f8f;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Spare-Parts-Navigator>p:nth-child(1) {
  cursor: pointer;
}

.Spare-Parts-Navigator>p:nth-child(5) {
  cursor: pointer;
}

.Selected {
  font-family: Roboto;
  font-size: 12px;
  color: white;
  margin-top: 7px;
  cursor: pointer;
  background-color: #0097b8;
}

.Bottom-Navigator {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.Bottom-Navigator>p {
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  margin-left: 4px;
  margin-right: 4px;
}

.Bottom-Navigator>input {
  width: 15px;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 12px;
  color: #8f8f8f;
  text-align: center;
  border-bottom: 1px solid #8f8f8f;
  background-color: transparent;
}

.Bottom-Navigator>p:nth-child(1) {
  cursor: pointer;
}

.Bottom-Navigator>p:nth-child(5) {
  cursor: pointer;
}

.Bottom-Navigator>p:nth-child(1):hover {
  font-size: 14px;
}

.Bottom-Navigator>p:nth-child(5):hover {
  font-size: 14px;
}

@media screen and (max-width: 1500px) {
  .Creator {
    margin-left: 20px;
    margin-right: 20px;
  }

  .Preview {
    width: 1200px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .Preview {
    width: 1100px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .Creator {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1125px) {
  .Preview {
    width: 1000px;
  }
}

@media screen and (max-width: 1022px) {
  .Preview {
    width: 900px;
  }
}

@media screen and (max-width: 635px) {
  .History-Header {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}
</style>
