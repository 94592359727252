import axios from "axios";

const live = "https://myexcel.app:3000/machines/"
//const url = "http://147.182.172.150:3000/machines/";

class ListMachineDetails {
  static List(token, id) {
    return axios.get(`${live}${id}`, { headers: { Authorization: `Bearer ${token}` } });
  }
}

export default ListMachineDetails;
