import axios from "axios";

const url = "https://myexcel.app:3000/technology/searchProductNumbers";

class List_Export_Schemes_According_To_Search {
  static List_Export_Schemes_According_To_Search(productNumber, page, token) {
    return axios.get(`${url}?productNumber=${productNumber}&page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}

export default List_Export_Schemes_According_To_Search;
