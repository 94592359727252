import axios from "axios";

const live = "https://myexcel.app:3000/machines/"
//const url = "http://147.182.172.150:3000/machines/";

class DeleteMachine {
  static Delete(id, token) {
    return axios.delete(`${live}${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default DeleteMachine;